<template>
    <el-upload v-model:file-list="fileList" class="upload-demo" :on-change="upload" :limit="1" :auto-upload="false"
        action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15">
        <el-button type="primary">上传文件</el-button>
    </el-upload>
    <iframe class="m-iframe" :src="iframeUrl"></iframe>
</template>
<script>
export default {
    name: 'PdfViewer',
    props: {
        msg: String
    },
}
</script>
<script setup>
import { ref } from 'vue'
const iframeUrl = ref()
const upload = (e) => {
    let fileUrl = URL.createObjectURL(e.raw);
	iframeUrl.value = fileUrl;

};

</script>
<style scoped>

.m-ppt-wrap {
	display: flex;
	flex-direction: column;
	height: 100%;
}
.m-iframe {
	width: 100%;
	flex: 1;
	border: none;
}
</style>
