<template>
  <div class="a" style="margin-top:100px;font-size: 20px;">数据中心</div>
</template>

<script>
export default {
    name: 'DataCenter',
    props: {
        msg: String
    },
}
</script>

<style>

</style>