import * as THREE from 'three';
import MATERIAL from "../js/material.js"

// 定义 CAPS.SelectionBoxLine 类
class SelectionBoxLine {

  constructor(v0, v1, f0, f1, selection) {

    // 创建 BufferGeometry 并添加顶点数据
    const lineGeometry = new THREE.BufferGeometry();
    const vertices = new Float32Array([
      v0.x, v0.y, v0.z,   // 第一个顶点
      v1.x, v1.y, v1.z    // 第二个顶点
    ]);
    lineGeometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
    lineGeometry.computeBoundingSphere();

    // 将几何体添加到 selection.lineGeometries 中
    selection.lineGeometries.push(lineGeometry);

    // 创建线条对象
    this.line = new THREE.LineSegments(lineGeometry, MATERIAL.BoxWireframe.clone());
    // 将线条添加到场景中的 displayMeshes 中
    selection.displayMeshes.add(this.line);

    // 将线条添加到 f0 和 f1 的 lines 数组中
    f0.lines.push(this);
    f1.lines.push(this);
    
  }

  // 该方法用于设置线条的高亮选中状态
  setHighlight(b) {
    this.line.material = b ? MATERIAL.BoxWireActive.clone() : MATERIAL.BoxWireframe.clone();
  }


}

export default SelectionBoxLine;
