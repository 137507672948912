<template>
  <div class="out">
    <Headercom />
    <div class="pro_inner">
      <el-tabs v-model="activeName" type="card" class="pro_demo-tabs" @tab-change="handleClick">
        <el-tab-pane label="进行中" name="first" :style="paneStyle('first')">
          <Projectitem ref="typevalueplus" :prolist="projectdata"></Projectitem>
        </el-tab-pane>
        <el-tab-pane label="已完成" name="second" :style="paneStyle('second')">
          <Projectitem ref="typevalueplus" :prolist="projectdata"></Projectitem>
        </el-tab-pane>
      </el-tabs>
      <div class="pro_type">类型</div>
      <el-select v-model="typevalue" placeholder="请选择" size="primary" filterable @change="typeselect"
        class="type_select">
        <el-option v-for="item in typeoptions" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>
      <el-input v-model="searchitem" class="search-input" placeholder="搜索项目">
        <template #append>
          <el-button type="primary" @click="searchclick">
            <el-icon>
              <Search />
            </el-icon>
          </el-button>
        </template>
      </el-input>
      <el-button color="#4976f3" :dark="isDark" @click="xmdialogshow" class="create-btn">新建项目</el-button>

    </div>
    <!-- <router-view></router-view> -->
    <el-dialog v-model="dialogFormVisible" title="新建项目" width="600px" center>
      <el-form ref="xmcreateRef" :model="ruleForm" :rules="rules" label-width="auto" class="demo-ruleForm"
        :size="formSize" status-icon>
        <el-form-item label="项目类型" required>
          <el-select v-model="ruleForm.type" placeholder="请选择一个类型">
            <el-option label="公建" value="0" />
            <el-option label="住宅" value="1" />
            <el-option label="市政" value="2" />
            <el-option label="水利" value="3" />
            <el-option label="其他" value="4" />
          </el-select>
        </el-form-item>
        <el-row>
          <el-col :span="12" class="el-col-mianji">
            <el-form-item label="项目面积" required prop="mianji">
              <el-input v-model="ruleForm.mianji" autocomplete="off"><template #append>m²</template></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="客户编号">
              <el-input v-model="ruleForm.khbh" type="number" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="项目名称" required prop="xmmc">
          <el-input v-model="ruleForm.xmmc" />
        </el-form-item>
        <el-row>
          <el-col :span="24">
            <el-form-item label="项目地址" required prop="xmdz">
              <el-cascader size="middle" :options="pcaTextArr" v-model="selectedOptions" @change="handlepositionChange">
              </el-cascader>
              <el-input v-model="ruleForm.xmdz" placeholder="请输入详细地址" style="margin-top:10px;" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="建设单位" required prop="jsdw">
          <el-input v-model="ruleForm.jsdw" />
        </el-form-item>
        <el-form-item label="设计单位" required prop="sjdw">
          <el-input v-model="ruleForm.sjdw" />
        </el-form-item>
        <el-form-item label="监理单位" required prop="jldw">
          <el-input v-model="ruleForm.jldw" />
        </el-form-item>
        <el-form-item label="施工单位" required prop="sgdw">
          <el-input v-model="ruleForm.sgdw" />
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="开工日期" required prop="kgdate">
              <el-date-picker v-model="ruleForm.kgdate" type="date" placeholder="请选择" style="width: 100%" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="竣工日期" required prop="jgdate">
              <el-date-picker v-model="ruleForm.jgdate" type="date" placeholder="请选择" style="width: 100%" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="项目封面" required prop="xmimage">
          <el-upload class="pro_avatar-uploader" action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15"
            :show-file-list="false" :on-change="handleAvatarSuccess" :limit="1" :auto-upload="false"
            :on-exceed="handleExceed">
            <img v-if="ruleForm.xmimage" :src="ruleForm.xmimage" class="pro_avatar" />
            <el-icon class="pro_avatar-uploader-icon">
              <Plus />
            </el-icon>
          </el-upload>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="ruleForm.xmbeizhu" />
        </el-form-item>
        <div class="care">请注意：所有信息一经填写将不可修改</div>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="createxm">
            创建
          </el-button>
          <el-button @click="createxmcancel">取消</el-button>

        </div>
      </template>
    </el-dialog>

  </div>
</template>
<!-- <script setup>
import { Search } from '@element-plus/icons-vue'

</script> -->
<script>
import Headercom from './head.vue'
import Projectitem from './projectitem.vue'
import { pcaTextArr } from 'element-china-area-data'
import { ref, reactive, onMounted, getCurrentInstance } from 'vue'
import { getCookie } from '../store/cookieUtil.js';
import handleError from '../config/HandleError.js'
import { ElMessage } from 'element-plus'
import '../css/home.css'
import { useStore } from 'vuex'
// import blobToBytes from '../js/blobToBytes.js'
export default {
  name: 'HomeComp',
  props: {
    msg: String,
  },
  components: {
    Headercom,
    Projectitem
  },
  setup() {

  },
  data() {
    return {
      pcaTextArr,
    }
  },
}
</script>
<script setup>
const { proxy } = getCurrentInstance()
const store = useStore()
const xmcreateRef = ref(null);
const activeColor = ref('#ffffff');
const searchitem = ref('')
const activeName = ref('first');
const dialogFormVisible = ref(false);
const typevalue = ref(-1);
const typevalueplus = ref('')
const projectdata = reactive([])
const typeoptions = reactive(
  [
    {
      value: -1,
      label: '全部',
    },
    {
      value: 0,
      label: '公建',
    },
    {
      value: 1,
      label: '住宅',
    },
    {
      value: 2,
      label: '市政',
    },
    {
      value: 3,
      label: '水利',
    },
    {
      value: 4,
      label: '其他',
    },

  ]
);
const ruleForm = reactive(
  {
    type: "0",
    mianji: "",
    khbh: "",
    xmmc: "",
    xmdz: "",
    jsdw: "",
    sjdw: "",
    jldw: "",
    sgdw: "",
    kgdate: "",
    jgdate: "",
    xmimage: "",
    xmbeizhu: ""
  }
);
const rules = reactive(
  {

    mianji: [
      { required: true, message: '请输入项目面积', trigger: 'blur' },

    ],
    // khbh: [
    //   { required: true, message: '请输入客户编号', trigger: 'blur' },

    // ],
    xmmc: [
      { required: true, message: '请输入项目名称', trigger: 'blur' }
    ],
    xmdz: [
      { required: true, message: '请输入详细地址', trigger: 'blur' }
    ],
    jsdw: [
      { required: true, message: '请输入建设单位', trigger: 'blur' }
    ],
    sjdw: [
      { required: true, message: '请输入设计单位', trigger: 'blur' }
    ],
    jldw: [
      { required: true, message: '请输入监理单位', trigger: 'blur' }
    ],
    sgdw: [
      { required: true, message: '请输入施工单位', trigger: 'blur' }
    ],
    kgdate: [
      { required: true, message: '请选择开工日期', trigger: 'blur' }
    ],
    jgdate: [
      { required: true, message: '请选择竣工日期', trigger: 'blur' }
    ],
    xmimage: [
      { required: true, message: '请选择项目封面图片', trigger: 'blur' }
    ]
  }
);
let selectedOptions = [];
// const imageUrl = "";
const user = JSON.parse(sessionStorage.getItem('user'))
const paneStyle = (paneName) => {
  return {
    'background-color': activeName.value === paneName ? activeColor.value : ''
  };
};
const handleClick = (tab) => {
  store.dispatch('prolisttypefun', tab);//放到store中
};
onMounted(async () => {
  //获取项目列表
  await fetchProjectData();
})
const typeselect = (e) => {
  //向子组件传值
  store.dispatch('typeselectfun', e)
}
const searchclick = () => {
  store.dispatch('searchclickfun', searchitem)
}
const xmdialogshow = () => {
  dialogFormVisible.value = true;
}
// let file = null;
const handleAvatarSuccess = (e) => {
  ruleForm.xmimage = URL.createObjectURL(e.raw);
  // file = e.raw;
}
const createxm = () => {
  xmcreateRef.value.validate(async (valid) => {
    if (!valid) {
      ElMessage.error("请完善表单信息，再提交！");
      return false;
    }

    try {
      // const byteArray = await blobToBytes(file);
      // 表单验证通过，执行提交逻辑
      const res = await new proxy.$request(proxy.$urls.m().CreateBIM +
        `?Token=${getCookie('token')}&CivilType=${ruleForm.type}&CivilSquare=${ruleForm.mianji}&CivilInternalNumber=${ruleForm.khbh}&CivilName=${ruleForm.xmmc}&CivilArea=${(selectedOptions.join('')).concat(ruleForm.xmdz)}&ConstructionCompany=${ruleForm.jsdw}&DesignCompany=${ruleForm.sjdw}&SupervisionCompany=${ruleForm.jldw}&ConstructionContractor=${ruleForm.sgdw}&Account=${user.emailname}&Description=${ruleForm.xmbeizhu}&CommenceDate=${ Date.parse(ruleForm.kgdate)}&CompletionDate=${Date.parse(ruleForm.jgdate)}&coverData=1,2,1`).modeget();
      if (res.status != 200) {
        new proxy.$tips(res.data.message, "warning").Mess_age()
        return;
      }
      else {
        if (res.data && res.data.Error == 0) {
          console.log(res)
          new proxy.$tips("创建成功", "success").Mess_age()
          dialogFormVisible.value = false;
          await fetchProjectData();
          dialogFormVisible.value = false;
        }
        else {
          const errorMessage = handleError(res);
          new proxy.$tips(errorMessage, "error").Mess_age()

        }
      }

    } catch (err) {
      console.log(err)
    }
  });
}
const createxmcancel = () => {
  dialogFormVisible.value = false;
  xmcreateRef.value.resetFields()
}
const handlepositionChange = (value) => {
  selectedOptions = value; // 选中的值
}
// 获取项目列表的函数
const fetchProjectData = async () => {
  const res = await new proxy.$request(proxy.$urls.m().GetBIM +
    `?Token=${getCookie('token')}&Account=${user.emailname}`).modeget();
  console.log(res);

  if (res.status != 200) {
    new proxy.$tips(res.data.message, "warning").Mess_age();
    return;
  }

  if (res.data && res.data.Error === 0) {
    projectdata.splice(0, projectdata.length, ...res.data.BIMARIntegrationInfosProto);
    console.log(res.data.BIMARIntegrationInfosProto);
    console.log(projectdata);
  } else {
    const errorMessage = handleError(res);
    new proxy.$tips(errorMessage, "error").Mess_age();
  }
};

</script>