
import * as THREE from 'three';
const CanvasOperation = class {
    constructor(camera, scene, renderer, controls) {
        this.camera = camera;
        this.scene = scene;
        this.renderer = renderer;
        this.controls = controls;
        this.timer = null;
        // 初始双指触摸的起始距离
        this.touchStartDistance = 0;
        this.animationFrameId = null;
        // 创建时钟以便于获取时间增量
        this.clock = new THREE.Clock();
        // 监听浏览器窗口变化
        window.addEventListener('resize', () => {
            this.camera.aspect = window.innerWidth / window.innerHeight;
            // 更新相机的投影矩阵
            this.camera.updateProjectionMatrix();
            // 调整渲染器的宽高
            this.renderer.setSize(window.innerWidth, window.innerHeight);
        })
        renderer.domElement.addEventListener('touchstart', this.onTouchStart.bind(this), false);
        renderer.domElement.addEventListener('touchmove', this.onTouchMove.bind(this), false);
        renderer.domElement.addEventListener('touchend', this.onTouchEnd.bind(this), false);
    }
    //监听鼠标滚动
    MouseWheel = (event) => {
        event.preventDefault();
        const { camera, renderer, controls } = this;
        let factor = 1; // 控制相机缩放的速度，数值越大缩放越快
        if (event.deltaY < 0) {
            factor = 0.3;
        } else {
            factor = -0.3;
        }
        // 获取鼠标的屏幕坐标，转换为WebGL坐标范围[-1,1]
        const rect = renderer.domElement.getBoundingClientRect();
        const mouseX = ((event.clientX - rect.left) / rect.width) * 2 - 1;
        const mouseY = -((event.clientY - rect.top) / rect.height) * 2 + 1;
        // 将屏幕坐标转换为三维世界坐标
        const vector = new THREE.Vector3(mouseX, mouseY, 0.5); // z=0.5表示在相机的视锥中间
        vector.unproject(camera); // 从屏幕坐标到世界坐标的反投影
        vector.sub(camera.position).normalize(); // 计算相机到点击点的方向向量
        const distance = controls.target.distanceTo(camera.position); // 当前相机到target的距离
        const moveDistance = factor * distance * 0.1; // 缩放的移动距离
        // 计算相机和目标的新位置
        camera.position.addScaledVector(vector, moveDistance);
        controls.target.addScaledVector(vector, moveDistance);
        // controls.target.set(0,0,0)
        // 每次滚动都会重置定时器
        if (this.timer) {
            clearTimeout(this.timer);  // 如果有现存的timer，先清除掉，避免重复调用
        }
        // 在最后一次滚动结束后 500ms 执行 controls.target.set(0,0,0)
        this.stopTimer = setTimeout(() => {
            const intersects = this.Centermouseraycaster();
            if (intersects.length > 0) {
                controls.target.copy(intersects[0].point)
                controls.update(); // 更新控制以应用新的 target 位置
            }

        }, 500);
    };

    // 监听触摸开始
    onTouchStart(event) {
        if (event.touches.length === 2) {
            // 计算双指起始间距
            this.touchStartDistance = this.getTouchDistance(event);
        }
    }

    // 监听触摸移动
    onTouchMove(event) {
        if (event.touches.length === 2) {
            const { camera, controls } = this;

            const currentDistance = this.getTouchDistance(event);
            const scaleFactor = currentDistance / this.touchStartDistance; // 缩放比例

            if (scaleFactor !== 1) {
                const distance = controls.target.distanceTo(camera.position);
                const moveDistance = (scaleFactor - 1) * distance * 0.05;  // 使用缩放因子来控制缩放距离

                const vector = new THREE.Vector3(0, 0, -1).applyQuaternion(camera.quaternion).normalize(); // 得到相机所面向的方向
                camera.position.addScaledVector(vector, moveDistance);  // 移动相机
            }
        }
    }

    // 获取双指间的距离
    getTouchDistance(event) {
        const dx = event.touches[0].clientX - event.touches[1].clientX;
        const dy = event.touches[0].clientY - event.touches[1].clientY;
        return Math.sqrt(dx * dx + dy * dy);
    }

    // 监听触摸结束
    onTouchEnd(event) {
        if (event.touches.length < 2) {
            // 重置双指状态
            this.touchStartDistance = 0;
        }
    }
    Centermouseraycaster = () => {
        const px = window.innerWidth / 2;
        const py = window.innerHeight / 2;
        const x = (px / window.innerWidth) * 2 - 1;
        const y = -(py / window.innerHeight) * 2 + 1;
        //创建一个射线投射器`Raycaster`
        const raycaster = new THREE.Raycaster();
        // 通过摄像机和鼠标位置更新射线
        raycaster.setFromCamera(new THREE.Vector2(x, y), this.camera);
        const groups = this.scene.children.filter(child => child instanceof THREE.Group);
        // 获取所有 Group 的子对象，也就是递归查找所有可能的 Mesh 对象
        let objectsToIntersect = [];
        groups.forEach(group => {
            group.traverse(child => {
                if (child instanceof THREE.Mesh) {
                    objectsToIntersect.push(child);  // 只添加 Mesh 对象
                }
            });
        });
        const intersects = raycaster.intersectObjects(objectsToIntersect);
        return intersects;
    }
}
export default CanvasOperation