<template>
    <el-table :data="notice_tableData" style="width: 100%;" @click="notice_confirm = true">
        <el-table-column fixed prop="title" label="消息类型" :width="getDynamicWidth(250)" align="center" />
        <el-table-column prop="content" label="内容" :width="getDynamicWidth(722)" align="center" />
        <el-table-column prop="time" label="时间" :width="getDynamicWidth(370)" align="center" />
        <el-table-column fixed="right" label="操作" align="center">
            <template #default>
                <el-button link type="primary" size="primary" @click="handleClick">
                    接受
                </el-button>
                <el-button link type="danger" size="primary">拒绝</el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-dialog v-model="notice_confirm" title="项目信息" width="700" :before-close="handleClose">
        <div class="projectimage">
            <el-image style="width: 120px; height: 120px" :src="xmimage" :fit="fit" />
        </div>

        <el-descriptions title="" column="2">
            <el-descriptions-item label="项目类型 :">公建</el-descriptions-item>
            <el-descriptions-item label="项目面积 :">1000m²</el-descriptions-item>
            <el-descriptions-item label="项目名称 :">XXXX项目名称</el-descriptions-item>
            <el-descriptions-item label="客户编号 :">123456
            </el-descriptions-item>
            <el-descriptions-item label="项目地址 :">浙江省 杭州市 西湖区 XX新村10幢
            </el-descriptions-item>
            <el-descriptions-item></el-descriptions-item>
            <el-descriptions-item label="开工日期 :">2023-12-20</el-descriptions-item>
            <el-descriptions-item label="竣工日期 :">2023-12-20</el-descriptions-item>
        </el-descriptions>
        <el-descriptions column="1">
            <el-descriptions-item label="建设单位 :">建发房地产集团有限公司</el-descriptions-item>
            <el-descriptions-item label="设计单位 :">建发房地产集团设计有限公司</el-descriptions-item>
            <el-descriptions-item label="监理单位 :">建发房地产集团监理有限公司</el-descriptions-item>
            <el-descriptions-item label="施工单位 :">建发房地产集团施工有限公司</el-descriptions-item>
            <el-descriptions-item label="备注 :">苟富贵会根据杨靖宇具有会计与苦呀口语看卡</el-descriptions-item>
        </el-descriptions>




        <template #footer>
            <div class="dialog-footer">
                <el-button @click="notice_confirm = false" type="primary">接受</el-button>
                <el-button type="danger" @click="notice_confirm = false" text>
                    拒绝
                </el-button>
            </div>
        </template>
    </el-dialog>
</template>
<script>
import "../css/notice.css"
export default {
    name: 'NoticeItem',
}
</script>
<script setup>
import { reactive, ref,onMounted } from 'vue'
const notice_confirm = ref(false);
const notice_tableData = reactive(
    [
        {
            title: "任务邀请",
            content: "befine1邀请你加入休息休息嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻任务",
            time: "2023.12.22 12:00 ",
        },
        {
            title: "任务邀请",
            content: "befine1邀请你加入休息休息嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻任务",
            time: "2023.12.22 12:00 ",
        }
    ]
)
onMounted(()=>{
    //请求所有notice数据
})
// const noticeitemStatus = computed(() => store.state.noticeitemStatus);
// watchEffect(() => {
//     if (noticeitemStatus.value === 'first') {
//         prolist1.arr = props.prolist.filter(item => item.FinishState === status);
//     }

// });
// const proinfo = reactive(

//     {
//         type: "公建",
//         mianji: "1000",
//         khbh: "89765435678",
//         xmmc: "项目名称啊啊啊啊啊啊啊啊",
//         xmdz: "北京市东城区",
//         jsdw: "18100000000",
//         sjdw: "XXX公司",
//         jldw: "XXX公司",
//         sgdw: "XXX公司",
//         kgdate: "2023-12-20",
//         jgdate: "2024-12-20",
//         xmimage: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
//         xmbeizhu: "dsvsvdvdv"
//     }


// )
const getDynamicWidth = (px) => {
  // 根据窗口的宽度动态计算列宽
  const ratio = window.innerWidth /2560; // 假设 1920 是你设计的基准宽度
  return Math.max(px * ratio, 10) + 'px'; // 保证最小宽度不小于原始值的 80%
}
</script>
