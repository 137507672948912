<template>
  <el-progress :percentage="upload_new_flag" v-if="upload_new_flag" class="upload_new_progress" />
  <div class="head">
    <!-- <div class="head_a">文件夹</div> -->
    <div class="head_b"><el-button type="primary" @click="newfolderclick">+ 新建文件夹</el-button></div>
  </div>
  <el-tree style="max-width: 600px" :data="treeData.arr" :props="defaultProps" @node-click="handleNodeClick"
    node-key="value" :default-expanded-keys="defaultShowNodes.arr" @node-expand="handleNodeExpand"
    @node-collapse="handleNodeCollapse" ref="folderTreeRef" class="file_tac" aria-disabled="false" draggable
    highlight-current="true" :expand-on-click-node="false" @node-drop="handleNodeDrop">
    <template #default="{ node, data }">
      <span class="custom-tree-node">
        <div class="file_icon_outter">
          <img src="../img/folder.svg" class="foldersvg">
          <span>{{ node.label }}</span>
        </div>

        <el-popover placement="bottom" trigger="hover"
          :popper-style="{ minWidth: '20px', padding: '0', width: 'auto' }">
          <template #reference>
            <el-image class="file_icon_style" :src="file_detail_icon" />
          </template>
          <template #default>
            <div class="file_detail_icon_inner">
              <div class="file_detail_a">
                <el-button type="primary" text :icon="Edit" size="small" @click="editfilenameclick(data)">编辑</el-button>
              </div>
              <div class="file_detail_b">
                <el-button type="info" text :icon="DocumentAdd" size="small"
                  @click="newfolderclickplus(data)">新增</el-button>
              </div>
              <div class="file_detail_c">
                <el-popconfirm title="确定删除这个文件夹?" @confirm="confirmEvent(data)">
                  <template #reference>
                    <el-button type="danger" text :icon="Delete" size="small">删除</el-button>
                  </template>
                </el-popconfirm>

              </div>

            </div>
          </template>

        </el-popover>

      </span>
    </template>
  </el-tree>

  <div class="query_head">
    <div class="query_title">
      <el-input v-model="keyvalue" placeholder="名称" class="query_file-input" @input="nameChange" />
      <el-select v-model="typevalue" placeholder="类型" class="query_type-select" @change="typeChange">
        <el-option v-for="item in file_typeoptions" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>
      <el-select v-model="uploadervalue" placeholder="上传者" class="query_uploader-select" @change="uploaderChange">
        <el-option v-for="item in uploader_file_options" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>
      <el-button text class="file-query" type="primary">查询</el-button>
      <el-upload class="upload-demo" action :limit="1" auto-upload="false" :show-file-list='false'
        :http-request="handleFileChange" :before-upload="beforeUpload" :on-progress="handleFileUploadProgress">
        <el-button class="query_file-uploader" type="primary" @click="uploadfileclick">上传文件</el-button>
      </el-upload>

      <!-- <input type="file" ref="fileInput" @change="handleFileChange" style="display: none;" :accept="acceptTypes" /> -->
    </div>



    <el-table :data="file_tableData.arr" row-key="_id" lazy v-loading="loading" element-loading-text="正在转换中,请稍后"
      :element-loading-spinner="svg" element-loading-svg-view-box="-10, -10, 50, 50"
      element-loading-background="rgba(122, 122, 122, 0.5)" @row-dblclick="rowdblclick" class="file_table">
      <el-table-column fixed prop="file_seq" label="序号" :width="getDynamicWidth(101)" align='center' sortable>
        <template #default="{ row }">
          <span v-if="isValidRow(row)">
            {{ row.seq + 1 }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="FileName" label="名称" :width="getDynamicWidth(540)" align='center' sortable
        show-overflow-tooltip>
        <template #default="{ row }">
          <div class="submited2"
            :style="{ marginLeft: (row.FileParentId == 0 && row.isPromoted) || row.children.length ? '0' : '2vh' }">
            <img :src="getImageSrc(row.FileType)">
            <label>{{ row.FileName }}</label>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="FileType" label="类型" :width="getDynamicWidth(160)" align='center' sortable>
        <template #default="{ row }">
          <span>
            {{ getFileTypeByValue(row.FileType) }}
          </span>
        </template>
      </el-table-column>

      <el-table-column prop="FileVersion" label="版本" :width="getDynamicWidth(130)" align='center' sortable />
      <el-table-column prop="UploadAccount" label="上传者" :width="getDynamicWidth(260)" align='center' sortable />
      <el-table-column prop="UploadTime" label="上传日期" :width="getDynamicWidth(220)" align='center' sortable>
        <template #default="{ row }">
          <span>
            {{ formatDate(row.UploadTime) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" align='center' :width="getDynamicWidth(384)">
        <template #default="{ row }">
          <div class="outter_file_operator">
            <div class="operator_file">

              <el-upload class="update-demo" action :limit="1" auto-upload="false" :show-file-list='false'
                :http-request="handleFileChange" :before-upload="beforeUpdate" :on-progress="handleFileUploadProgress"
                :on-change="handleFileUpdateProgress">
                <el-button link type="primary" size="primary" @click="refreshClick(row)">
                  更新
                </el-button>
              </el-upload>
              <el-button link type="danger" size="primary" @click="deleteRow(row)">删除</el-button>
              <el-button link type="primary" size="primary" @click="downloadClick(row)">下载</el-button>

            </div>
            <el-progress v-if="row.progress" :percentage="row.progress" :stroke-width="15" :status="row.status_progress"
              striped striped-flow color="green" :duration="10" class="progress" />
          </div>

        </template>
      </el-table-column>
    </el-table>

  </div>
  <!-- <el-button type="primary" style="position: absolute;left: 800px;top: 500px;" @click="docxClick">预览docx</el-button> -->
  <!-- <el-button type="primary" style="position: absolute;left: 800px;top: 700px;" @click="xlsxClick">预览xlsx</el-button> -->
  <!-- <input type="file" @change="handleChange" style="position: absolute;left: 800px;top: 500px;"/> -->
  <!-- <el-dialog v-model="uploaddialog" title="上传文件" width="500" venter>
    <el-form :model="fileform">
      <el-form-item label="上传类型" :label-width="formLabelWidth" required>
        <el-select v-model="fileform.filetype" placeholder="类型">
          <el-option v-for="item in file_typeoptions" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="上传RVT格式" :label-width="formLabelWidth" required>
        <el-upload v-model:file-list="fileform.fileListRVT" class="rwupload-demo"
          action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15" multiple :auto-upload="false">
          <el-button class="wenjian"><el-icon>
              <Plus />
            </el-icon>选择文件</el-button>
        </el-upload>
      </el-form-item>

    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button type="primary" @click="uploader_submit">上传</el-button>
        <el-button @click="uploaddialog = false">
          取消
        </el-button>
      </div>
    </template>
  </el-dialog> -->
  <el-dialog v-model="refreshdialog" title="上传文件(更新)" width="500" venter>
    <el-form :model="refreshform">
      <el-form-item label="上传类型" :label-width="formLabelWidth" required>
        <el-select v-model="refreshform.refreshtype" placeholder="类型">
          <el-option v-for="item in file_typeoptions" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="上传文件  " :label-width="formLabelWidth" required>
        <el-upload v-model:file-list="refreshform.filelistplus" class="rwupload-demo"
          action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15" multiple :on-preview="handlePreview"
          :on-remove="handleRemove" :before-remove="beforeRemove" :limit="9" :on-exceed="handleExceed">
          <!-- <el-button type="primary">Click to upload</el-button> -->
          <el-button class="wenjian"><el-icon>
              <Plus />
            </el-icon> 选择文件</el-button>
          <template #tip>
            <div class="rwel-upload__tip">
              jpg/png files with a size less than 500KB.
            </div>
          </template>
        </el-upload>
      </el-form-item>


    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button type="primary" @click="refreshdialog = false">上传</el-button>
        <el-button @click="refreshdialog = false">
          取消
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import '../css/file.css'
export default {
  name: 'FileA',
  props: {
    msg: String
  },
};
</script>
<script setup>
import { reactive, ref, getCurrentInstance, onMounted } from 'vue';
import { Edit, Delete, DocumentAdd } from '@element-plus/icons-vue';
import blobToBytes from '../js/blobToBytes.js'
import { getCookie } from '../store/cookieUtil.js';
import handleError from '../config/HandleError.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import fileConvert from '@/js/fileConvert.js';
import { getFileType, getFileTypeByValue, getImageSrc, navigateBasedOnFileType } from '@/config/filetype.js';
import formatDate from "../config/formatDate.js"
import NodeClick from "../js/NodeClick.js"
const { proxy } = getCurrentInstance()
const projectinfo = JSON.parse(sessionStorage.getItem("projectinfo"))
const user = JSON.parse(sessionStorage.getItem("user"))
const nodeclick = new NodeClick();
const fileconvert = new fileConvert();
const file_detail_icon = ref(require('@/img/detail.svg'))
const keyvalue = ref("");
const uploadervalue = ref('')
const loading = ref(false)
const typevalue = ref('')
const upload_new_flag = ref(0)
const folderTreeRef = ref()
const refreshdialog = ref(false)
const treeData = reactive({
  arr: []
})
const defaultShowNodes = reactive({
  arr: []
})
let filelist = null;
const file_typeoptions = reactive(
  [
    {
      label: "GLB",
      value: 0
    },
    {
      label: "RVT",
      value: 1
    }, {
      label: "PDF",
      value: 2
    }, {
      label: "PPT",
      value: 3
    },
    {
      label: "DOCX",
      value: 4

    },
    {
      label: "XLSX",
      value: 5

    },
    {
      label: "DWG",
      value: 6

    },
    {
      label: "OTHER",
      value: 7

    },
  ]
)
const uploader_file_options = reactive([])
const defaultProps = reactive({
  children: 'children',
  label: 'label',
});
// const load = (row, treeNode, resolve) => {
//   setTimeout(() => {
//     resolve([
//       {
//         fileid: 11,
//         file_name: "临安农村信用社建筑模型",
//         file_type: "RVT",
//         file_version: "V2.0",
//         file_uploader: "丁晓禹",
//         file_uptime: "2024.07.05 11:12",
//       },
//       {
//         fileid: 12,
//         file_name: "临安农村信用社建筑模型",
//         file_type: "RVT",
//         file_version: "V1.0",
//         file_uploader: "丁晓禹",
//         file_uptime: "2024.06.22 17:08",
//       }
//     ]);
//   }, 1000);
// }

const file_tableData = reactive({
  arr: []
})
// const fileform = reactive(
//   {
//     filetype: "",
//     fileListRVT: ""
//   }
// )
let nodeclickId = null;
let ids = null;
const handleNodeClick = (data) => {
  console.log(data);
  nodeclickId = data.value;
  ids = nodeclick.traverseAndCollectChildren(data);
  let fileData = filelist.filter(file => ids.includes(file.FolderId));
  console.log(fileData)
  file_tableData.arr = fileconvert.buildTree(fileData);
  console.log(file_tableData.arr)

};
const refreshform = reactive(
  {
    refreshtype: "",
    filelistplus: ""
  }
)
let edit_flag = null;
const refreshClick = (data) => {
  edit_flag = data;
}
const rowdblclick = (e) => {
  console.log(e);
  navigateBasedOnFileType(e)

  // sessionStorage.setItem('fileinfo', JSON.stringify(e))
  // router.push({
  //   name: 'bimviewer'
  // })
}
const beforeUpload = (file) => {
  if (file.type != "" || file.type != null || file.type != undefined) {
    //计算文件的大小
    const isLt5M = file.size / 1024 / 1024 < 1024;
    //如果大于500M
    if (!isLt5M) {
      new proxy.$tips("文件太大,请重新选择", "warning").Mess_age()
      return false;
    }
    //如果文件类型不在允许上传的范围内
    // if (fileType.includes(FileExt)) {
    //   return true;
    // }
    // else {
    //   this.$message.error("上传文件格式不正确!");
    //   return false;
    // }
  }

}
const beforeUpdate = (file) => {
  //限定选择文件类型
  const uploadType = {
    0: '.glb',
    1: '.rvt',
    2: '.pdf',
    3: '.pptx',
    4: '.docx',
    5: '.xlsx',
    6: '.dwg'
  }
  if (file.type != "" || file.type != null || file.type != undefined) {
    //截取文件的后缀，判断文件类型
    const FileExt = file.name.replace(/.+\./, "").toLowerCase();
    //计算文件的大小
    const isLt5M = file.size / 1024 / 1024 < 1024;
    //如果大于500M
    if (!isLt5M) {
      new proxy.$tips("文件太大,请重新选择", "warning").Mess_age()
      return false;
    }
    if (edit_flag.fileType !== getFileType(FileExt)) {
      console.log(edit_flag)
      new proxy.$tips("文件类型不正确，请选择" + getFileTypeByValue(edit_flag.FileType) + "格式", "warning").Mess_age()
      return false;
    }
  }
  // acceptTypes.value = uploadType[data.FileType];
}
const handleFileUpdateProgress = (file) => {
  console.log(file)
}
const uploadfileclick = () => {
  edit_flag = null;
  // acceptTypes.value = ""
}
let temp_fileProgress = []
const handleFileChange = async (event) => {
  if (nodeclickId == null) {
    ElMessage.warning("请选择文件夹！")
    return
  }
  const temp_edit = edit_flag;
  const file = event.file;
  let uploadSuccess = true; // 用于跟踪上传成功
  const nameWithoutExtension = file.name.replace(/\.\w+$/, "");
  const chunkSize = 1024 * 1024;
  const totalChunks = Math.ceil(file.size / chunkSize);
  for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
    const start = chunkIndex * chunkSize;
    const end = Math.min(start + chunkSize, file.size);
    const chunk = file.slice(start, end);
    // 使用 Blob 转换为base64字符串
    const byteArray = await blobToBytes(chunk);
    try {
      const file_data = new URLSearchParams();
      file_data.append('Token', getCookie('token'));
      file_data.append('ProjectId', projectinfo._id);
      file_data.append('Account', user.emailname);
      file_data.append('FileName', nameWithoutExtension);
      file_data.append('fileType', getFileType(file.name.split('.').pop().toLowerCase()));
      file_data.append('ChunkIndex', chunkIndex);
      file_data.append('TotalChunks', totalChunks);
      file_data.append('FileChunk', byteArray);
      // file_data.append('Description', Description);
      file_data.append('FolderId', nodeclickId);
      file_data.append('FileSize', file.size);
      let res = null;
      if (temp_edit) {
        if (temp_edit.FileParentId) {
          file_data.append('FileParentId', temp_edit.FileParentId);
        }
        else {
          file_data.append('FileParentId', temp_edit._id);
        }

        res = await new proxy.$request(proxy.$urls.m().UpdateFile, file_data).modepost();
      }
      else {
        res = await new proxy.$request(proxy.$urls.m().UploadFile, file_data).modepost();
      }

      console.log(res)
      if (res.status != 200) {
        new proxy.$tips(res.data.message, "warning").Mess_age()
        uploadSuccess = false; // 上传状态设置为失败
        break; // 不再继续上传
      }
      else {
        if (res.data && res.data.Error == 0) {
          // 更新进度条
          if (temp_edit) {
            temp_edit.progress = Math.round(((chunkIndex + 1) / totalChunks) * 100);
            console.log(temp_edit.progress)
          }
          else {
            upload_new_flag.value = Math.round(((chunkIndex + 1) / totalChunks) * 100);
          }


        }
        else {
          const errorMessage = handleError(res);
          new proxy.$tips(errorMessage, "error").Mess_age();
          uploadSuccess = false;
          break;
        }
      }
    }
    catch (e) {
      new proxy.$tips("上传失败", "error").Mess_age();
      uploadSuccess = false;
      if (temp_edit) {
        temp_edit.status_progress = 'warning'
      }

      break;
    }
  }
  if (uploadSuccess) {
    new proxy.$tips("上传成功", "success").Mess_age();
    if (temp_edit) {
      temp_edit.progress = 0;
      temp_edit.status_progress = 'success'
    }
    else {
      upload_new_flag.value = 0;
    }
    //保存此刻进度条的值
    temp_fileProgress = file_tableData.arr;
    await refreshFileList(temp_fileProgress);
  }
};

const isValidRow = (row) => {
  return row.children && row.children.length > 0 || (row.FileParentId == 0 && row.isPromoted);
}

const getDynamicWidth = (px) => {
  // 根据窗口的宽度动态计算列宽
  const ratio = window.innerWidth / 2560; // 假设 1920 是你设计的基准宽度
  return Math.max(px * ratio, 10) + 'px'; // 保证最小宽度不小于原始值的 80%
}
const handleFileUploadProgress = (file) => {
  console.log(file)
}
const downloadClick = async (item) => {
  loading.value = true;
  const chunkSize = 1024 * 1024;
  const totalChunks = Math.ceil(item.FileSize / chunkSize);
  let base64List = []
  let flag = true;
  for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
    const file_data = new URLSearchParams();
    file_data.append('Token', getCookie('token'));
    file_data.append('ProjectId', projectinfo._id);
    file_data.append('Account', user.emailname);
    file_data.append('FileId', item._id);
    // file_data.append('Fileversion', item.FileVersion);
    file_data.append('StartPosition', chunkIndex * chunkSize);
    await new proxy.$request(proxy.$urls.m().DownloadFile, file_data).modepost().then(res => {
      if (res.status != 200) {
        new proxy.$tips(res.data.message, "warning").Mess_age()
        flag = false;
        return;
      }
      else {
        if (res.data.Error == 0 && res && res.data && res.data.FileContent && res.data.FileContent.$binary && res.data.FileContent.$binary.base64) {
          console.log(res)
          base64List.push(res.data.FileContent.$binary.base64);
        }
        else {
          const errorMessage = handleError(res);
          new proxy.$tips(errorMessage, "error").Mess_age();
          flag = false;
          return;
        }
      }
    })

  }
  if (flag == false) return;
  const combinedBase64 =padBase64String(base64List.join(''));
  const byteCharacters = atob(combinedBase64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'image/svg+xml' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  console.log( link.href)
  link.download = item.FileName + "." + getFileTypeByValue(item.FileType);
  document.body.appendChild(link);
  link.click(); // 触发下载
  loading.value = false;
  document.body.removeChild(link);

}
function padBase64String(str) {
    const padLength = 4 - (str.length % 4);
    return str + '='.repeat(padLength === 4 ? 0 : padLength);
}
onMounted(async () => {
  await refreshFileList(temp_fileProgress)
  await refreshFolderTree();
  const res = await new proxy.$request(proxy.$urls.m().GetUser + `?Token=${getCookie('token')}&ProjectId=${projectinfo._id}&Account=${user.emailname}`).modeget();
  if (res.status != 200) {
    new proxy.$tips(res.data.message, "warning").Mess_age()
    return;
  }
  else {
    if (res.data && res.data.Error == 0) {
      uploader_file_options.length = 0;
      console.log(res)
      res.data.Permissions.forEach(user => {
        uploader_file_options.push({
          label: user.AcountName,
          value: user.AcountName
        });
      });
    }
    else {
      const errorMessage = handleError(res);
      new proxy.$tips(errorMessage, "error").Mess_age()

    }
  }
})
const newfolderclick = () => {
  ElMessageBox.prompt('请输入文件夹名称', '新建', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    inputPattern: /.+/,
    inputErrorMessage: '文件夹名称不能为空',
  })
    .then(async ({ value }) => {
      await createFolder(value, 0);
    })

}
// const xlsxClick = () => {
//   router.push({
//     name: "excelviewer",
//   });
// }
const editfilenameclick = (data) => {
  ElMessageBox.prompt('请输入文件夹名称', '重命名', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    inputPattern: /^(?!.*[<>]).+$/,
    inputErrorMessage: '文件夹名称不能为空',
    inputValue: data.label,
  })
    .then(async ({ value }) => {
      await new proxy.$request(proxy.$urls.m().ModifyFolder + `?Token=${getCookie('token')}&ProjectId=${projectinfo._id}&Account=${user.emailname}&FolderId=${data.value}&FolderName=${value}&ParentID=-1`).modeget().then(res => {
        console.log(res)
        if (res.status != 200) {
          new proxy.$tips(res.data.message, "warning").Mess_age()
          return;
        }
        else {
          if (res.data && res.data.Error == 0) {
            new proxy.$tips("修改成功", "success").Mess_age()
            refreshFolderTree();
          }
          else {
            const errorMessage = handleError(res);
            new proxy.$tips(errorMessage, "error").Mess_age();
          }
        }
      })
    })
    .catch(() => {
      ElMessage({
        type: 'info',
        message: '取消修改',
      })
    })
}
const newfolderclickplus = (data) => {
  ElMessageBox.prompt('请输入文件夹名称', '新建', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    inputPattern: /^(?!.*[<>]).+$/,
    inputErrorMessage: '文件夹名称不能为空',
  })
    .then(async ({ value }) => {
      await createFolder(value, data.value);
    })

}
const confirmEvent = async (data) => {
  await new proxy.$request(proxy.$urls.m().DeleteFolder + `?Token=${getCookie('token')}&ProjectId=${projectinfo._id}&Account=${user.emailname}&FolderId=${data.value}`).modeget().then(res => {
    console.log(res)
    if (res.status != 200) {
      new proxy.$tips(res.data.message, "warning").Mess_age()
      return;
    }
    else {
      if (res.data && res.data.Error == 0) {
        refreshFolderTree();
      }
      else {
        const errorMessage = handleError(res);
        new proxy.$tips(errorMessage, "error").Mess_age();
      }
    }
  })
}
const refreshFolderTree = async () => {
  await new proxy.$request(proxy.$urls.m().GetFolder + `?Token=${getCookie('token')}&ProjectId=${projectinfo._id}&Account=${user.emailname}`).modeget().then(res => {
    if (res.status != 200) {
      new proxy.$tips(res.data.message, "warning").Mess_age()
      return;
    }
    else {
      if (res.data && res.data.Error == 0) {
        const floderdata = res.data.FoldersInfoProto;
        treeData.arr = fileconvert.convertToTree(floderdata);
      }
      else {
        const errorMessage = handleError(res);
        new proxy.$tips(errorMessage, "error").Mess_age();
      }
    }
  })
  // 当数据更新了，在dom中渲染后，自动执行该函数

}
const refreshFileList = async (temp_fileProgress) => {
  await new proxy.$request(proxy.$urls.m().GetBIMFile + `?Token=${getCookie('token')}&ProjectId=${projectinfo._id}&Account=${user.emailname}`).modeget().then(res => {
    if (res.status != 200) {
      new proxy.$tips(res.data.message, "warning").Mess_age()
      return;
    }
    else {
      if (res.data && res.data.Error == 0) {
        filelist = res.data.FileShareInfo;
        filelist = fileconvert.initializeFileData(filelist, temp_fileProgress);//添加进度条相关信息

        if (ids && ids.length) {
          // file_tableData.arr = filelist.filter(file => ids.includes(file.FolderId));
          let fileData = filelist.filter(file => ids.includes(file.FolderId));
          file_tableData.arr = fileconvert.buildTree(fileData);

        }
        // else{
        //   file_tableData.arr = filelist;
        // }
      }
      else {
        const errorMessage = handleError(res);
        new proxy.$tips(errorMessage, "error").Mess_age();
      }
    }
  })
}
const createFolder = async (value, flag) => {
  await new proxy.$request(proxy.$urls.m().CreateFolder + `?Token=${getCookie('token')}&ProjectId=${projectinfo._id}&Account=${user.emailname}&ParentID=${flag}&FolderName=${value}`).modeget().then(res => {
    console.log(res)
    if (res.status != 200) {
      new proxy.$tips(res.data.message, "warning").Mess_age()
      return;
    }
    else {
      if (res.data && res.data.Error == 0) {
        refreshFolderTree();
      }
      else {
        const errorMessage = handleError(res);
        new proxy.$tips(errorMessage, "error").Mess_age();
      }
    }
  })
}
// 树节点展开
const handleNodeExpand = (data) => {
  // 保存当前展开的节点
  let flag = false
  defaultShowNodes.arr.some(item => {
    if (item === data.value) { // 判断当前节点是否存在， 存在不做处理
      flag = true
      return true
    }
  })
  if (!flag) { // 不存在则存到数组里
    defaultShowNodes.arr.push(data.value)
  }
}
// 树节点关闭
const handleNodeCollapse = (data) => {
  console.log(data)
  defaultShowNodes.arr.some((item, i) => {
    if (item === data.value) {
      // 删除关闭节点
      defaultShowNodes.arr.splice(i, 1)
    }
  })
  removeChildrenIds(data)
}
//删除树子节点
const removeChildrenIds = (data) => {
  //懒加载的时候想要删除子节点  得在加载下级数据时添加上data.children
  if (data.children) {
    data.children.forEach(function (item) {
      const index = defaultShowNodes.arr.indexOf(item.value)
      if (index != -1) {
        defaultShowNodes.arr.splice(index, 1)
      }
      removeChildrenIds(item)
    })
  }
}
const deleteRow = (data) => {
  ElMessageBox.confirm('此操作将永久删除该文件, 是否继续?', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  }).then(async () => {
    // 确认删除
    await new proxy.$request(proxy.$urls.m().DeleteFile + `?Token=${getCookie('token')}&ProjectId=${projectinfo._id}&Account=${user.emailname}&FileId=${data._id}&Fileversion=${data.FileVersion}`).modeget().then(res => {
      console.log(res)
      if (res.status != 200) {
        new proxy.$tips(res.data.message, "warning").Mess_age()
        return;
      }
      else {
        if (res.data && res.data.Error == 0) {
          refreshFileList(temp_fileProgress);
          new proxy.$tips("删除成功", "success").Mess_age();
        }
        else {
          const errorMessage = handleError(res);
          new proxy.$tips(errorMessage, "error").Mess_age();
        }
      }
    })
  }).catch(() => {
    // 取消删除
    ElMessage({
      type: 'info',
      message: '已取消删除',
    })
  })
}
const nameChange = (data) => {
  file_tableData.arr = filelist.filter(file =>
    file.FileName.includes(data)
  );
}
const typeChange = (data) => {
  file_tableData.arr = filelist.filter(file =>
    file.FileType == data
  );
}
const uploaderChange = (data) => {
  file_tableData.arr = filelist.filter(file =>
    file.UploadAccount == data
  );
}
const handleNodeDrop = async (nodefrom, nodeto, position) => {
  console.log(nodefrom, nodeto, position)
  let pos = null;
  if (position == 'inner') {
    pos = nodeto.data.value;
  }
  else {
    pos = nodeto.data.parentID;
  }
  await new proxy.$request(proxy.$urls.m().ModifyFolder + `?Token=${getCookie('token')}&ProjectId=${projectinfo._id}&Account=${user.emailname}&FolderId=${nodefrom.data.value}&ParentID=${pos}`).modeget().then(res => {
    console.log(res)
    if (res.status != 200) {
      new proxy.$tips(res.data.message, "warning").Mess_age()
      return;
    }
    else {
      if (res.data && res.data.Error == 0) {
        new proxy.$tips("修改成功", "success").Mess_age()
        refreshFolderTree();
      }
      else {
        const errorMessage = handleError(res);
        new proxy.$tips(errorMessage, "error").Mess_age();
      }
    }
  })
}
</script>
