<template>
    <!-- <div class="xmname">
        <div class="xmname_left"></div>
        
        <div class="xmname_right">
            {{ projectinfo.CivilName }}
        </div>
        
    </div> -->
    <div class="aa">
        <router-link :to="{ name: 'renwulist', query: { flag: 0 } }" class="allxm" :style="{ color: xmColor }"
            @click="updateColor">项目任务</router-link>
        <router-link :to="{ name: 'renwulist', query: { flag: 1 } }" class="create" :style="{ color: createColor }"
            @click="updateCreateColor">发布任务</router-link>
        <router-link :to="{ name: 'renwulist', query: { flag: 2 } }" class="accept" :style="{ color: acceptColor }"
            @click="updateAcceptColor">接收任务</router-link>
            
        <el-button color="#4976f3" @click="addrenwu = true">新建任务</el-button>
    </div>
    <router-view></router-view>
    <!-- <div class="demo-pagination-block">
        <el-pagination v-model:current-page="currentPage3" v-model:page-size="pageSize3" :small="small"
            :disabled="disabled" :background="background" layout="prev, pager, next, jumper" :total="2"
            @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div> -->
    <el-dialog v-model="addrenwu" title="新建任务" width="500" venter center round @opened="loadData">
        <el-form :model="rwform" ref="rwcreateRef" :rules="rules">
            <el-form-item label="任务名称" :label-width="formLabelWidth" required prop="name">
                <el-input v-model="rwform.name" placeholder="请输入任务名称" />
            </el-form-item>
            <el-form-item label="任务描述" :label-width="formLabelWidth" required prop="describe">
                <el-input v-model="rwform.describe" placeholder="请输入任务描述" />
            </el-form-item>
            <el-form-item label="项目成员" :label-width="formLabelWidth" required>
                <el-select v-model="rwform.chengyuan" placeholder="请选择项目成员" multiple>
                    <template #header>
                        <el-checkbox v-model="checkAll" :indeterminate="indeterminate" @change="handleCheckAll">
                            All
                        </el-checkbox>
                    </template>
                    <el-option v-for="item in xmcyoptions" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
            </el-form-item>
            <el-form-item label="上传文件" :label-width="formLabelWidth">
                <el-upload v-model:file-list="rwform.fileList" class="rwupload-demo"
                    action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15" multiple :limit="9"
                    :auto-upload="false">
                    <!-- <el-button type="primary">Click to upload</el-button> -->
                    <el-button class="wenjian"><el-icon>
                            <Plus />
                        </el-icon>选择文件</el-button>
                    <!-- <template #tip>
                        <div class="rwel-upload__tip">
                            jpg/png files with a size less than 500KB.
                        </div>
                    </template> -->
                </el-upload>
            </el-form-item>
            <el-form-item label="截止日期" required prop="jzdate">
                <el-date-picker v-model="rwform.jzdate" type="datetime" placeholder="请选择" style="width: 100%" :disabled-date="disabledDate"/>
            </el-form-item>

        </el-form>
        <template #footer>
            <div class="dialog-footer">
                <el-button type="primary" @click="addrenwuclick">新建</el-button>
                <el-button @click="addrenwucancel">
                    取消
                </el-button>
            </div>
        </template>
    </el-dialog>
</template>
<script>

import "../css/renwuinfo.css"
import { ref, reactive, watch, getCurrentInstance} from 'vue';
import { ElMessage } from 'element-plus'
import { getCookie } from '../store/cookieUtil.js';
import handleError from '../config/HandleError.js';
import eventBus from '../js/eventBus.js';
// import { useStore } from 'vuex'
export default {
    name: 'RenwuInfo',
    props: {
        msg: String,
    },

}
</script>
<script setup>
// const store = useStore()
const { proxy } = getCurrentInstance()
const checkAll = ref(false)
const indeterminate = ref(false)
// const activeName1 = ref("first");
// const activeName2 = ref("first");
const addrenwu = ref(false);
// const currentPage3 = ref(1);
// const pageSize3 = ref(5);
// const small = ref(false);
// const disabled = ref(false);
const rwcreateRef = ref(null);
const projectinfo = JSON.parse(sessionStorage.getItem("projectinfo"))
const user = JSON.parse(sessionStorage.getItem("user"))
// const handleSizeChange = () => {
//     console.log("jkliol");
// }
// const handleCurrentChange = () => {
//     console.log("ghjhyu");
// }
const createColor = ref('#000000'); // 创建的颜色
const acceptColor = ref('#000000'); // 接收的颜色
const xmColor = ref('#4976F3');
const flag = ref(0)
const updateCreateColor = () => {
    createColor.value = '#4976F3'; // 点击创建链接时更新颜色样式
    acceptColor.value = '#000000'; // 将接收链接颜色重置
    xmColor.value = '#000000'
    flag.value = 1
};
const updateColor=()=>{
    createColor.value = '#000000'; 
    acceptColor.value = '#000000'; 
    xmColor.value = '#4976F3'
    flag.value=0;
}

const updateAcceptColor = () => {
    acceptColor.value = '#4976F3'; // 点击接收链接时更新颜色样式
    createColor.value = '#000000'; // 将创建链接颜色重置
    xmColor.value = '#000000'
    flag.value = 2
};
const disabledDate = (time) => {
    return time.getTime() < new Date().getTime();
}
const xmcyoptions = reactive([]);
const rwform = reactive(
    {
        name: "",
        describe: "",
        chengyuan: [],
        fileList: "",
        jzdate: ""
    }
)
const rules = reactive({
    describe: [
        { required: true, message: '请输入任务描述', trigger: 'blur' },
    ],
    jzdate: [
        { required: true, message: '请选择截止日期', trigger: 'blur' },
    ],
    name: [
        { required: true, message: '请输入任务名称', trigger: 'blur' },
    ]
})
const addrenwuclick = async () => {
    await rwcreateRef.value?.validate(async (valid) => {
        if (!valid) {
            ElMessage.error("请完善表单信息，再提交！");
            return false;
        }
        console.log(rwform.fileList, rwform.chengyuan, rwform.name)
        const res = await new proxy.$request(proxy.$urls.m().AddAssigment + `?Token=${getCookie('token')}&ProjectId=${projectinfo._id}&Account=${user.emailname}&TaskName=${rwform.name}&accountinfos=${rwform.chengyuan}&Description=${rwform.describe}&deadline=${Date.parse(rwform.jzdate)}&FileInfos=${rwform.fileList}`).modeget();
        console.log(res)
        if (res.status != 200) {
            new proxy.$tips(res.data.message, "warning").Mess_age()
            return;
        }
        else {
            if (res.data && res.data.Error == 0) {
                eventBus.emit('taskrefresh');
                new proxy.$tips("添加成功", "success").Mess_age()
               
            }
            else {
                const errorMessage = handleError(res);
                new proxy.$tips(errorMessage, "error").Mess_age()

            }
        }
        addrenwu.value = false;
    })

    //发送请求
}
const addrenwucancel = () => {
    addrenwu.value = false;
    rwcreateRef.value.resetFields()
}
watch(() => rwform.chengyuan, (newval) => {
    // console.log(newval)
    // console.log( xmcyoptions.length)
    if (newval.length === 0) {
        checkAll.value = false
        indeterminate.value = false
    } else if (newval.length === xmcyoptions.length) {
        checkAll.value = true
        indeterminate.value = false
    } else {
        indeterminate.value = true
    }
})
const handleCheckAll = (val) => {
    indeterminate.value = false
    if (val) {
        rwform.chengyuan = xmcyoptions.map((_) => _.value)
        console.log(rwform.chengyuan)
    } else {
        rwform.chengyuan = []
        console.log(rwform.chengyuan)
    }

}
const loadData = async () => {
    const res = await new proxy.$request(proxy.$urls.m().GetUser + `?Token=${getCookie('token')}&ProjectId=${projectinfo._id}&Account=${user.emailname}`).modeget();
    if (res.status != 200) {
        new proxy.$tips(res.data.message, "warning").Mess_age()
        return;
    }
    else {
        if (res.data && res.data.Error == 0) {
            xmcyoptions.length = 0;
            res.data.Permissions.forEach(user => {
                xmcyoptions.push({
                    label: user.AcountName,
                    value: user.AcountName
                });
            });

        }
        else {
            const errorMessage = handleError(res);
            new proxy.$tips(errorMessage, "error").Mess_age()

        }



    }
}

</script>