import * as THREE from 'three';

const NodeClick = class {
    constructor(scene) {
        this.scene = scene;
    }
    //点击显影按钮
    handleButtonClick = (nodeData) => {
        const { scene } = this;
        if (nodeData.modelShowflag === true) {
            nodeData.modelShowflag = false;
        } else {
            nodeData.modelShowflag = true;
        }
        // 同步子节点的状态
        this.syncChildrenModelShowFlag(nodeData, nodeData.modelShowflag);
        //循环遍历children获取name
        const names = this.traverseAndCollectNames(nodeData);
        names.forEach(name => {
            const mesh = scene.getObjectByName(name);
            mesh.visible = nodeData.modelShowflag;
        });
        console.log(nodeData);
    }
    //同步图标
    syncChildrenModelShowFlag = (node, newStatus) => {
        if (node.children && node.children.length > 0) {
            node.children.forEach(child => {
                child.modelShowflag = newStatus; // 同步子节点的值
                this.syncChildrenModelShowFlag(child, newStatus); // 递归调用子节点
            });
        }
    }
    computeCentroid(points) {
        const centroid = new THREE.Vector3(0, 0, 0);
        // 遍历所有点，将它们相加
        points.forEach(point => {
            centroid.add(point);
        });
        // 除以点的数量，计算平均值
        centroid.divideScalar(points.length);
        return centroid;  // 返回质心
    }

    traverseAndCollectNames(node) {
        // 保存所有 name 的数组
        let names = [];
        // 获取当前节点的 name
        if (node.name) {
            names.push(node.name);
        }
        // 如果这个节点有 children，遍历每一个孩子节点
        if (node.children && Array.isArray(node.children)) {
            for (let child of node.children) {
                // 递归处理孩子节点，并将结果合并到 names 数组中
                names = names.concat(this.traverseAndCollectNames(child));
            }
        }
        return names;
    }
    traverseAndCollectMaterials(node, flag) {
        if (node.material) {
            node.material.wireframe = flag
        }
        // 如果这个节点有 children，遍历每一个孩子节点
        if (node.children && Array.isArray(node.children)) {
            for (let child of node.children) {
                this.traverseAndCollectMaterials(child, flag)
            }
        }
    }
    traverseAndCollectLeafNodesAndMaterials(node) {
        // 保存所有叶子节点的数组
        let leafNodesArray = [];
        // 保存所有叶子节点的材质数组
        let materialArray = [];

        // 递归处理每个节点
        const traverse = (node) => {
            // 如果节点有子节点，递归处理子节点
            if (node.children && node.children.length > 0) {
                // 遍历每个子节点
                for (let child of node.children) {
                    traverse(child);
                }
            } else {
                // 如果没有子节点（即叶子节点），保存节点和材质
                leafNodesArray.push(node);

                // 如果节点有材质属性，保存该节点的材质
                if (node.material) {
                    if (Array.isArray(node.material)) {
                        // 如果材质是数组，展开并加入 materialArray
                        materialArray.push(...node.material);
                    } else {
                        // 单一材质
                        materialArray.push(node.material);
                    }
                }
            }
        };

        // 从根节点开始递归
        traverse(node);

        // 返回两个数组：叶子节点和它们的材质
        return {
            leafNodes: leafNodesArray,
            materials: materialArray
        };
    }

    //存贴图
    traverseMaterialsCollectTexture(node) {
        // 保存所有叶子节点的数组
        let TextureMeshArray = [];
        // 保存所有叶子节点的材质数组
        let materialArray = [];
        const traverse = (node) => {
            if (node.children && node.children.length > 0) {
                // 遍历每个子节点
                for (let child of node.children) {
                    traverse(child);
                }
            } else {
                // 如果节点有贴图
                if (node.material && node.material.map && node.material.map.isTexture) {
                    TextureMeshArray.push(node);
                    materialArray.push(node.material);
                    const m = node.material.clone();
                    m.map = null;
                    m.color.setHex(0x55564d)
                    node.material = m;
                }
            }
        };

        // 从根节点开始递归
        traverse(node);

        // 返回两个数组：叶子节点和它们的材质
        return {
            TextureNodes: TextureMeshArray,
            materials: materialArray
        };
    }
    getBoundingSphereCenterInWorld(mesh) {
        const geometry = mesh.geometry;
        if (!geometry || !geometry.attributes.position) {
            console.warn('该网格没有适当的几何体数据');
            return null;
        }
        geometry.computeBoundingSphere();
        const boundingCenterLocal = geometry.boundingSphere.center.clone();
        const boundingCenterWorld = mesh.localToWorld(boundingCenterLocal);
        return boundingCenterWorld;
    }
    traverseallMesh(node) {
        const traverse = (node) => {
            if (node.children && node.children.length > 0) {
                // 遍历每个子节点
                for (let child of node.children) {
                    traverse(child);
                }
            } else if (node.isMesh) {
                node.material = new THREE.MeshStandardMaterial({
                    // color: 0xffffff,
                    // transparent: true,
                    // opacity: 0,
                    visible: false
                });
                // 模型边线设置
                const edges = new THREE.EdgesGeometry(node.geometry, 10);
                const edgesMaterial = new THREE.LineBasicMaterial({
                    color: 0x000000,

                })
                const line = new THREE.LineSegments(edges, edgesMaterial);
                node.add(line);
            }

        };
        traverse(node);

    }
    findParameters(object) {
        // 如果对象有userData，并且userData中存在Parameters，则返回该值
        if (object && object.userData && 'Parameters' in object.userData) {
            return object.userData.Parameters;
        }
        // 如果没有父对象，则说明已经到最顶级对象，没有找到Parameters
        if (!object.parent) {
            return null;  // 未找到Parameters，返回null或者undefined
        }
        // 递归向上查找父级对象的Parameters，直到最顶级或找到为止
        return this.findParameters(object.parent);
    }
    shadowMap(node) {
        const traverse = (node) => {
            if (node.children && node.children.length > 0) {
                // 遍历每个子节点
                for (let child of node.children) {
                    traverse(child);
                }
            } else if (node.isMesh) {
                if (node.material instanceof THREE.MeshStandardMaterial || node.material instanceof THREE.MeshPhysicalMaterial) {
                    // 设置环境映射
                    node.material.envMapIntensity = 1;
                    // 启用阴影
                    node.castShadow = true;  // 投射阴影
                    node.receiveShadow = true; // 接收阴影
                    // 如果需要，还可以调整物理材质的其他参数
                    // node.material.roughness = 15; // 控制粗糙度
                    // node.material.metalness = 15; // 控制金属度
                }
            }

        };
        traverse(node);

    }
    traverseAndCollectChildren(node) {
        let ids = [];
        // 获取当前节点的 name
        if (node.value) {
            ids.push(node.value);
        }
        // 如果这个节点有 children，遍历每一个孩子节点
        if (node.children && Array.isArray(node.children)) {
            for (let child of node.children) {
                // 递归处理孩子节点，并将结果合并到 names 数组中
                ids = ids.concat(this.traverseAndCollectChildren(child));
            }
        }
        return ids;
    }
}
export default NodeClick