<template>
    <el-collapse v-model="activeNames" @change="handleChange" class="rw_out" v-if="renwuinfo.arr.length">
        <el-collapse-item v-for="(item, index) in renwuinfo.arr" :name="item._id" :key="index">
            <template #title>
                <div :class="['title-bg', !item.status ? 'in-progress' : 'completed']">
                    <div class="renwuseq">{{ item.TaskCode }}</div>
                    <div class="renwumc">{{ item.TaskName }}</div>
                    <!-- <img src="../img/editxm.svg" class="edit-icon" alt=""> -->
                    <el-icon color="#FFFFFF" class="edit-icon" @click.stop="editrenwuplus($event, item)">
                        <Edit />
                    </el-icon>
                    <el-image class="edit-icon_delete" :src="deleterw"
                        @click.stop="deleterenwuplus($event, item.TaskCode)"></el-image>
                    <div class="jxz" v-if="!item.status">进行中</div>
                    <div class="jxz" v-else>已完成</div>
                    <el-icon class="item_icon-left" @click="deleterw">
                        <template v-if="activeNames.includes(item.name)">
                            <CaretBottom />
                        </template>
                        <template v-else>
                            <CaretRight />
                        </template>
                    </el-icon>

                </div>
            </template>
            <div class="time">
                <div class="fbtime"> 发布时间 :&nbsp;&nbsp;{{ formatDate(item.createdTime) }}</div>
                <div class="fbz">发布者 :&nbsp;&nbsp;{{ item.creatorName }}</div>
                <div class="jztime">截止时间 :{{ formatDate(item.deadline) }}</div>
            </div>
            <div class="rwdescribe">
                <div class="rwdescribe_text">任务内容：</div>
                <div class="rwdescribe_content">{{ item.description }}</div>
            </div>
            <!-- <el-row>
                <el-col :span="4" v-for="(file, index) in item.fileList" :key="index" :offset="1">
                    <div class="rwitem_upload">
                        <div class="rwitem_submited">
                            <img src="../img/ppt.png">
                            <label>小房子模型.xls</label>
                        </div>
                        <img src="../img/fjdownload.svg" style="margin-right:10px">
                        <img src="../img/fjdelete.svg">
                    </div>
                </el-col>
            </el-row> -->
            <div class="inner2">
                <div class="upload_release" v-if="isUserInAccountInfos(item)">
                    <el-upload v-model:file-list="item.FileInfos" class="upload-demo__release"
                        action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15" multiple
                        :auto-upload="false">
                        <!-- <el-button type="primary">Click to upload</el-button> -->
                        <el-button class="fujian">上传附件</el-button>
                        <!-- <template #tip>
                            <div class="el-upload__tip">
                                jpg/png files with a size less than 500KB.
                            </div>
                        </template> -->

                    </el-upload>
                    <el-row class="item_el_row__release">
                        <el-col :span="4" v-for="(file, index) in item.fileList" :key="index" :offset="1">
                            <div class="rwitem_upload">
                                <div class="rwitem_submited" :title="file.name">
                                    <img src="../img/ppt.png">
                                    <label>{{ file.name }}</label>
                                </div>
                                <img src="../img/fjdownload.svg" style="margin-right:10px">
                                <img src="../img/fjdelete.svg" style="margin-right:10px">
                            </div>
                        </el-col>
                    </el-row>
                </div>
                <el-row class="item_el_row" v-else>
                    <el-col :span="4" v-for="(file, index) in item.fileList" :key="index" :offset="1">
                        <div class="rwitem_upload">
                            <div class="rwitem_submited" :title="file.name">
                                <img src="../img/ppt.png">
                                <label>{{ file.name }}</label>
                            </div>
                            <img src="../img/fjdownload.svg" style="margin-right:10px">
                            <img src="../img/fjdelete.svg" style="margin-right:10px">
                        </div>
                    </el-col>
                </el-row>
                <el-table :data="item.Submissioninfo" style="width: 100%" class="datatable">
                    <!-- align="center" -->
                    <el-table-column fixed prop="UserAccount" label="接收者" :width="getDynamicWidth(200)" align="center" />
                    <el-table-column prop="Comments" label="回复" :width="getDynamicWidth(525)" align="center" show-overflow-tooltip />
                    <el-table-column prop="SubmissionTime" label="提交时间" :width="getDynamicWidth(300)" align="center">
                        <template #default="{ row }">
                            <span>
                                {{ formatDate(row.SubmissionTime) }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="fileInfos" label="提交成果" :width="getDynamicWidth(500)" align="center">
                        <template #default>
                            <div class="submitting">
                                <div class="submited">
                                    <img src="../img/ppt.png">
                                    <label>小房子模型</label>
                                </div>
                                <div class="re" v-if="isUserInAccountInfos(item)">
                                    <el-button link type="primary">下载</el-button>
                                </div>
                                <div class="ac" v-else>
                                    <el-button link type="primary" >上传</el-button>
                                    <el-button link type="danger">删除</el-button>
                                </div>

                            </div>
                        </template>

                    </el-table-column>
                    <el-table-column fixed="right" label="操作" align="center">
                        <template #default="{ row }">
                            <div v-if="!completedRows.includes(row._id)&&isUserInAccountInfos(item)">
                                <el-button link type="primary" @click="finishhandleClick(row, item)">完成</el-button>
                                <el-button link type="danger" @click="rejectHandleClick(row)">驳回</el-button>
                            </div>
                            <el-button link type="primary" @click="handleClickreply(row,item.TaskCode)"
                                v-else-if="!item.status&&!isUserInAccountInfos(item)">回复</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

        </el-collapse-item>
    </el-collapse>
    <el-empty description="暂无任务" v-else class="empty_outter" :image="emptyimageurl" />
    <el-dialog v-model="editrenwu" title="编辑任务" width="500" venter center round @opened="loadData">
        <el-form :model="rwform" ref="rweditRef" :rules="rules">
            <el-form-item label="任务描述" :label-width="formLabelWidth" required prop="describe">
                <el-input v-model="rwform.describe" placeholder="请输入任务描述" />
            </el-form-item>
            <el-form-item label="项目成员" :label-width="formLabelWidth" required>
                <el-select v-model="rwform.chengyuan" placeholder="请选择项目成员" multiple>
                    <template #header>
                        <el-checkbox v-model="checkAll" :indeterminate="indeterminate" @change="handleCheckAll">
                            All
                        </el-checkbox>
                    </template>
                    <el-option v-for="item in xmcyoptions" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
            </el-form-item>
            <el-form-item label="上传文件" :label-width="formLabelWidth">
                <el-upload v-model:file-list="rwform.fileList" class="rwupload-demo"
                    action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15" multiple
                    :on-preview="handlePreview" :on-remove="handleRemove" :before-remove="beforeRemove" :limit="9"
                    :on-exceed="handleExceed" :auto-upload="false">
                    <!-- <el-button type="primary">Click to upload</el-button> -->
                    <el-button class="wenjian"><el-icon>
                            <Plus />
                        </el-icon>选择文件</el-button>
                    <!-- <template #tip>
                        <div class="rwel-upload__tip">
                            jpg/png files with a size less than 500KB.
                        </div>
                    </template> -->
                </el-upload>
            </el-form-item>
            <el-form-item label="截止日期" required prop="jzdate">
                <el-date-picker v-model="rwform.jzdate" type="datetime" placeholder="请选择" style="width: 100%" />
            </el-form-item>

        </el-form>
        <template #footer>
            <div class="dialog-footer">
                <el-button type="primary" @click="editrenwuclick">保存</el-button>
                <el-button @click="editrenwucancel">
                    取消
                </el-button>
            </div>
        </template>
    </el-dialog>
    <el-dialog v-model="replycontent" title="回复" width="500" venter center round>
        <el-form :model="replyform">
            <el-form-item label="文字回复" :label-width="formLabelWidth">
                <el-input v-model="replyform.describe" maxlength="500" style="width: 440px" placeholder="请输入"
                    show-word-limit type="textarea" />
            </el-form-item>

            <el-form-item label="上传文件" :label-width="formLabelWidth">
                <el-upload v-model:file-list="replyform.fileList" class="rwupload-demo"
                    action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15" multiple
                    :limit="9"
                   >
                    <!-- <el-button type="primary">Click to upload</el-button> -->
                    <el-button class="wenjian"><el-icon>
                            <Plus />
                        </el-icon>选择文件</el-button>
                    <!-- <template #tip>
                        <div class="rwel-upload__tip">
                            jpg/png files with a size less than 500KB.
                        </div>
                    </template> -->
                </el-upload>
            </el-form-item>


        </el-form>
        <template #footer>
            <div class="dialog-footer">
                <el-button type="primary" @click="replyrenwuclick">确定</el-button>
                <el-button @click="replyrenwucancel">
                    取消
                </el-button>
            </div>
        </template>
    </el-dialog>
</template>
<script>
import "../css/renwuitem.css"

export default {
    name: 'RenwuItem',


}
</script>
<script setup>
import { ref, reactive, computed, watchEffect, getCurrentInstance } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useStore } from 'vuex'
import formatDate from "../config/formatDate.js"
import { getCookie } from '../store/cookieUtil.js';
import handleError from '../config/HandleError.js'
import eventBus from '../js/eventBus.js';
// import blobToBytes from '../js/blobToBytes.js'
const { proxy } = getCurrentInstance()
const store = useStore()
const editrenwu = ref(false)
const activeNames = ref("1");
const emptyimageurl = require('@/img/暂无任务.svg')
const deleterw = ref(require("../img/deleterw.svg"))
const projectinfo = JSON.parse(sessionStorage.getItem("projectinfo"))
const user = JSON.parse(sessionStorage.getItem('user'))
let completedRows = [] // 用于跟踪已完成的行
const xmcyoptions = reactive([]);
const checkAll = ref(false)
const indeterminate = ref(false)
const replycontent = ref(false)
const replyform = reactive(
    {
        describe: "",
        fileList: ""
    }
)
const getDynamicWidth = (px) => {
  // 根据窗口的宽度动态计算列宽
  const ratio = window.innerWidth /2560; // 假设 1920 是你设计的基准宽度
  return Math.max(px * ratio, 10) + 'px'; // 保证最小宽度不小于原始值的 80%
}
const finishhandleClick = (row, item) => {
    ElMessageBox.confirm(
        '此操作一经提交将无法恢复，是否继续？',
        'Warning',
        {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(async () => {
            console.log(row, item)
            const res = await new proxy.$request(proxy.$urls.m().CheckAssigment +`?Token=${getCookie('token')}&Account=${row.UserAccount}&ProjectId=${projectinfo._id}&TaskCode=${encodeURIComponent(item.TaskCode)}&Status=${Number(item.status)}&ModificationSuggestions=2`).modeget();
            console.log(res)
            if (res.status != 200) {
                new proxy.$tips(res.data.message, "warning").Mess_age()
                return;
            }
            else {
                if (res.data && res.data.Error == 0) {
                    completedRows.push(row._id)
                    new proxy.$tips('完成', "success").Mess_age()

                }
                else {
                    const errorMessage = handleError(res);
                    new proxy.$tips(errorMessage, "error").Mess_age()
                }
            }

        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '已取消删除'
            });
        });
}
const rweditRef = ref(null)

const rules = reactive({
    describe: [
        { required: true, message: '请输入任务描述', trigger: 'blur' },
    ],
    jzdate: [
        { required: true, message: '请选择截止日期', trigger: 'blur' },
    ],
})
const rwform = reactive(
    {
        describe: "",
        chengyuan: [],
        fileList: "",
        jzdate: ""
    }
)
let assignment = null;
const editrenwuplus = (e, item) => {
    e.stopPropagation();
    assignment = item;
    editrenwu.value = true
}
const deleterenwuplus = (e, TaskCode) => {
    e.stopPropagation();
    ElMessageBox.confirm(
        '此操作一经提交将无法恢复，是否继续？',
        'Warning',
        {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(async () => {
            const res = await new proxy.$request(proxy.$urls.m().DeleteAssigment +
                `?Token=${getCookie('token')}&Account=${user.emailname}&ProjectId=${projectinfo._id}&Taskcode=${encodeURIComponent(TaskCode)}`).modeget();
            console.log(res)
            if (res.status != 200) {
                new proxy.$tips(res.data.message, "warning").Mess_age()
                return;
            }
            else {
                if (res.data && res.data.Error == 0) {
                    eventBus.emit('taskrefresh');
                    new proxy.$tips('任务已删除', "success").Mess_age()
                }
                else {
                    const errorMessage = handleError(res);
                    new proxy.$tips(errorMessage, "error").Mess_age()
                }
            }

        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '已取消删除'
            });
        });

}
const editrenwuclick = async () => {
    await rweditRef.value?.validate(async (valid) => {
        if (!valid) {
            ElMessage.error("请完善信息，再提交！");
            return false;
        }
        const res = await new proxy.$request(proxy.$urls.m().ModifyAssigment +
            `?Token=${getCookie('token')}&Account=${user.emailname}&ProjectId=${projectinfo._id}&ProjectAssignmentId=${assignment._id}&accountinfos=${rwform.chengyuan}&Description=${rwform.describe}&deadline=${Date.parse(rwform.jzdate)}&FileInfos=1,1,3`).modeget();
        console.log(res)
        if (res.status != 200) {
            new proxy.$tips(res.data.message, "warning").Mess_age()
            return;
        }
        else {
            if (res.data && res.data.Error == 0) {
                eventBus.emit('taskrefresh');
                new proxy.$tips('成功', "success").Mess_age()
                editrenwu.value = false;
            }
            else {
                const errorMessage = handleError(res);
                new proxy.$tips(errorMessage, "error").Mess_age()
            }
        }

    })

    //发送请求
}
const editrenwucancel = () => {
    editrenwu.value = false;
    rweditRef.value.resetFields()
}
const renwuinfo = reactive({
    arr: []
})
const rwlist = computed(() => store.state.rwlist);
const status = computed(() => store.state.status);
watchEffect(() => {
    const temp_status = Number(status.value);
    renwuinfo.arr = rwlist.value.filter(item => {
        // 定义过滤条件
        const shouldInclude = temp_status == -1
            ? item.status === true || item.status === false
            : item.status === Boolean(temp_status);

        // 只保留符合条件的 item
        return shouldInclude;
    }).map(item => {
        // 创建一个新的对象
        const newItem = { ...item }; // 深拷贝 item

        // 如果 Submissioninfo 为空，根据 accountinfos 动态生成数据
        if (newItem.Submissioninfo.length === 0) {
            newItem.Submissioninfo = newItem.accountinfos.map(account => ({
                UserAccount: account,
                Comments: "", // 默认评论为空
                SubmissionTime: "", // 默认提交时间为空
                fileInfos: [] // 默认文件数组为空
            }));
        }

        return newItem; // 返回修改后的 item
    });
    if (renwuinfo.arr.length > 0) {
        activeNames.value = [renwuinfo.arr[0]._id]; // 默认展开第一项
    }

});

const loadData = async () => {
    rwform.describe = assignment.description;
    rwform.chengyuan = assignment.accountinfos;
    rwform.jzdate = formatDate(assignment.deadline);
    const res = await new proxy.$request(proxy.$urls.m().GetUser + `?Token=${getCookie('token')}&ProjectId=${projectinfo._id}&Account=${user.emailname}`).modeget();
    if (res.status != 200) {
        new proxy.$tips(res.data.message, "warning").Mess_age()
        return;
    }
    else {
        if (res.data && res.data.Error == 0) {
            xmcyoptions.length = 0;
            res.data.Permissions.forEach(user => {
                xmcyoptions.push({
                    label: user.AcountName,
                    value: user.AcountName
                });
            });

        }
        else {
            const errorMessage = handleError(res);
            new proxy.$tips(errorMessage, "error").Mess_age()

        }



    }
}
const isUserInAccountInfos = (item) => {
    
    return item.creatorName == user.emailname&&sessionStorage.getItem("flag")!=2;
};
const handleCheckAll = (val) => {
    indeterminate.value = false
    if (val) {
        rwform.chengyuan = xmcyoptions.map((_) => _.value)
        console.log(rwform.chengyuan)
    } else {
        rwform.chengyuan = []
        console.log(rwform.chengyuan)
    }

}
// watchEffect(() => {
//     renwuinfo.arr = rwlist.value.filter(item =>
//         status === -1
//             ? item.FinishState === 0 || item.FinishState === 1
//             : item.FinishState === status
//     );
// });
const replyrenwuclick = async () => {
    const TaskCode = sessionStorage.getItem("Taskcode")
    const item=JSON.parse(sessionStorage.getItem("item"))
    const res = await new proxy.$request(proxy.$urls.m().SubmitAssigment + `?Token=${getCookie('token')}&ProjectId=${projectinfo._id}&Account=${item.UserAccount}&TaskCode=${encodeURIComponent(TaskCode)}&Comments=${replyform.describe}`).modeget();
    console.log(res)
    if (res.status != 200) {
        new proxy.$tips(res.data.message, "warning").Mess_age()
        return;
    }
    else {
        if (res.data && res.data.Error == 0) {
            eventBus.emit('taskrefresh');
            new proxy.$tips("回复成功", "success").Mess_age()
        }
        else {
            const errorMessage = handleError(res);
            new proxy.$tips(errorMessage, "error").Mess_age()

        }



    }
    replycontent.value = false;
}
const replyrenwucancel = () => {
    replycontent.value = false;
    // replyRef.value.resetFields()
}
const handleClickreply = (row,Taskcode) => {
    //回复
    replycontent.value = true;
    sessionStorage.setItem("Taskcode", Taskcode)
    sessionStorage.setItem("item", JSON.stringify(row))
}
</script>