
import router from "@/router"
const FileType = {
    GLB: 0,
    RVT: 1,
    PDF: 2,
    PPT: 3,
    DOCX: 4,
    XLSX: 5,
    DWG: 6,
    OTHER: 7
};


// 定义获取文件类型的方法
function getFileType(type) {
    switch (type) {
        case 'glb':
            return FileType.GLB;
        case 'rvt':
            return FileType.RVT;
        case 'pdf':
            return FileType.PDF;
        case 'ppt':
            return FileType.PPT;
        case 'docx':
            return FileType.DOCX;
        case 'xlsx':
            return FileType.XLSX;
        case 'dwg':
            return FileType.DWG;
        default:
            return FileType.OTHER;
    }
}
// 定义根据值获取文件类型的方法
function getFileTypeByValue(value) {
    for (const [key, val] of Object.entries(FileType)) {
        if (val === value) {
            return key; // 返回文件类型字符串
        }
    }
    return 'unknown'; // 如果找不到，返回 unknown
}
function getImageSrc(fileType) {
    switch (fileType) {
        case 0:
            return require('@/img/glb.svg'); // PDF 图标的路径
        case 1:
            return require('@/img/RVT.svg'); // Word 文档图标的路径
        case 2:
            return require('@/img/pdf.svg'); // 默认图标路径
        case 3:
            return require('@/img/ppt.svg'); // PowerPoint 图标的路径
        case 4:
            return require('@/img/word.svg');
        case 5:
            return require('@/img/excel.svg');
        case 6:
            return require('@/img/dwg.svg');
        // 其他文件类型的处理
        default:
            return require('@/img/pdf.svg'); // 默认图标路径
    }
}
function navigateBasedOnFileType(e) {
    switch (e.FileType) {
        case 0: {
            sessionStorage.setItem('fileinfo', JSON.stringify(e));
            router.push({ name: 'bimviewer' });
            break; // 可选：使用 break 结束 case
        }
        case 1: {
            sessionStorage.setItem('fileinfo', JSON.stringify(e));
            router.push({ name: 'pptxviewer' });
            break;
        }
        case 2: {
            sessionStorage.setItem('fileinfo', JSON.stringify(e));
            router.push({ name: 'docxviewer' });
            break;
        }
        case 3: {
            sessionStorage.setItem('fileinfo', JSON.stringify(e));
            router.push({ name: 'excelviewer' });
            break;
        }
        case 4: {
            sessionStorage.setItem('fileinfo', JSON.stringify(e));
            router.push({ name: 'pdfviewer' });
            break;
        }
        default: {
            console.warn('未知文件类型:', e.FileType);
            break;
        }
    }
}
export { getFileType, getFileTypeByValue, getImageSrc, navigateBasedOnFileType };