<template>
    <div class="docx-preview-wrap">
        <input type="file" @change="upload" />
        <div id="bodyContainer"></div>
    </div>
</template>
<script>
export default {
    name: 'DocxViewer',
    props: {
        msg: String
    },

}
</script>
<script setup>
import { renderAsync } from 'docx-preview';
// import {onMounted } from 'vue';
// import { useRoute } from 'vue-router'; 
// const route = useRoute();
// onMounted(() => {
//     let bodyContainer = document.getElementById("bodyContainer");
//     console.log(route.query.url)
//     renderAsync(
//         route.query.url,
//         bodyContainer, // HTMLElement 渲染文档内容的元素,
//         null, // HTMLElement, 用于呈现文档样式、数字、字体的元素。如果为 null，则将使用 bodyContainer。
//         docxOptions // 配置
//     )
// })
const upload=(e)=>{
    let bodyContainer = document.getElementById("bodyContainer");
    renderAsync(
        e.target.files[0],
        bodyContainer, // HTMLElement 渲染文档内容的元素,
        null, // HTMLElement, 用于呈现文档样式、数字、字体的元素。如果为 null，则将使用 bodyContainer。
        docxOptions // 配置
    )
}
const docxOptions = {
    className: "kaimo-docx-666", // string：默认和文档样式类的类名/前缀
    inWrapper: true, // boolean：启用围绕文档内容的包装器渲染
    ignoreWidth: false, // boolean：禁用页面的渲染宽度
    ignoreHeight: false, // boolean：禁止渲染页面高度
    ignoreFonts: true, // boolean：禁用字体渲染
    breakPages: false, // boolean：在分页符上启用分页
    ignoreLastRenderedPageBreak: true, // boolean：在 lastRenderedPageBreak 元素上禁用分页
    experimental: false, // boolean：启用实验功能（制表符停止计算）
    trimXmlDeclaration: true, // boolean：如果为true，解析前会从 xml 文档中移除 xml 声明
    useBase64URL: false, // boolean：如果为true，图片、字体等会转为base 64 URL，否则使用URL.createObjectURL
    useMathMLPolyfill: false, // boolean：包括用于 chrome、edge 等的 MathML polyfill。
    showChanges: false, // boolean：启用文档更改的实验性渲染（插入/删除）
    debug: true, // boolean：启用额外的日志记录
};


</script>
<style scoped>
.docx-preview-wrap {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    /* 确保子元素垂直布局 */
}

#bodyContainer {
    height: 100%;
    flex-grow: 1;
    /* 允许容器在可用空间中增长 */
    overflow-y: auto;
    /* 允许垂直滚动 */
    padding: 5px;
    /* 可选：添加一些内边距，防止内容和边缘粘连 */

}
</style>
