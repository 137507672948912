<template>
  <div class="bimtitle">
    <el-image :src="Navigatelogourl" fit="fill" class="logo" />
    <label class="filename">{{ filename }}&nbsp;&nbsp;{{ file_version }}</label>
    <el-image :src="Closeurl" fit="fill" @click="CloseBimtitle" class="title_icon_style"></el-image>
  </div>
  <div class="bimout">
    <div id="my-three"></div>
  </div>

  <div class="left" v-if="isLeftVisible" :class="containerClass">
    <div class="goujianTitle">
      <div class="goujianText">构件列表</div>
      <el-icon @click="CloseList" class="icon_style">
        <Close />
      </el-icon>
    </div>
    <div class="lineview"></div>
    <div class="searchkeyinput"> <el-input v-model="searchkeyinput" placeholder="搜索构件" clearable @input="filterTree"
        prefix-icon="Search" /></div>
    <el-tree :data="GLBdata" :props="defaultProps" class="leftContent" @node-click="handleNodeClick"
      :filter-node-method="filterNode" ref="treeRef" :expand-on-click-node="false" highlight-current="true">
      <template #default="{ node, data }">
        <div class="tree-node-container">
          <span>{{ node.label }}</span>
          <el-image class="icon_style" :src="data.modelShowflag === true ? ModelShow : ModelHide"
            @click.stop="handleButtonClick(data)" />
        </div>
      </template>
    </el-tree>
  </div>

  <div class="right" v-if="flag == 1">
    <vue-drag-resize :w="VueDragplus.initialWidth" :h="VueDragplus.initialHeight" :x="VueDragplus.initialX"
      :y="VueDragplus.initialY" :parent="true" :minWidth="200" :minHeight="300" class="draggableright">
      <!-- <div class="FamilyNameTitle">{{ Name }}</div> -->
      <div class="FamilyNameTitle">
        <div class="FamilyName">属性列表</div>
        <el-icon @click="CloseListFamilyName" class="icon_style">
          <Close />
        </el-icon>
      </div>
      <div class="lineview"></div>
      <div class="demo-collapse">
        <el-collapse v-model="activeNames" @change="handleChange">
          <el-collapse-item v-for="(group, index) in leaf_Parameters" :key="index" :name="group.GroupName"
            class="custom-collapse-item">
            <template #title>
              <div class="collapse-title">
                <el-icon class="icon-left">
                  <template v-if="activeNames.includes(group.GroupName)">
                    <CaretBottom />
                  </template>
                  <template v-else>
                    <CaretRight />
                  </template>
                </el-icon>
                <span>{{ group.GroupName }}</span>
              </div>
            </template>
            <template #default>
              <el-descriptions column="1" size="Default" class="custom-descriptions">
                <el-descriptions-item v-for="(param, paramIndex) in group.Parameters" :key="paramIndex"
                  :label="param.name" label-class-name="el-descriptions-item">{{ param.value }}</el-descriptions-item>

              </el-descriptions>
            </template>

          </el-collapse-item>

        </el-collapse>
      </div>
    </vue-drag-resize>
  </div>
  <div class="group1">

    <div> <el-image :src="pouqieurl" alt="" fit="fill" @click="styleclick" class="icon_style"></el-image>
      <div>主页</div>
    </div>
    <div> <el-image :src="modelurl" alt="" fit="fill" @click="returnmodel" class="icon_style"></el-image>
      <div>原模型</div>
    </div>
    <div><el-image :src="styleurl" fit="fill" @click="pouqieclick" class="icon_style"></el-image>
      <div>剖切</div>
    </div>
    <div><el-image :src="measureurl" alt="" fit="fill" @click="measureclick" class="icon_style"></el-image>
      <div>测量</div>
    </div>



  </div>
  <div class="group2">
    <div><el-image :src="rotateurl" alt="" fit="fill" @click="rotateclick" class="icon_style"></el-image>
      <div>旋转</div>
    </div>
    <div><el-image :src="treeurl" alt="" fit="fill" @click="treeclick" class="icon_style"></el-image>
      <div>构件树</div>
    </div>
    <div><el-image :src="atrributeurl" alt="" fit="fill" @click="atrributeclick" class="icon_style"></el-image>
      <div>属性</div>
    </div>
    <div><el-image :src="gridHelperurl" alt="" fit="fill" @click="gridHelperclick" class="icon_style"></el-image>
      <div>网格</div>
    </div>
    <div><el-image :src="axisHelperurl" alt="" fit="fill" @click="axisHelperclick" class="icon_style"></el-image>
      <div>坐标轴</div>
    </div>
    <div><el-image :src="bgcurl" alt="" fit="fill" @click="bgcclick" class="icon_style"></el-image>
      <div>背景</div>
    </div>
    <div> <el-image :src="fullsceneurl" alt="" fit="fill" @click="fullsceneclick" class="icon_style"></el-image>
      <div>全景</div>
    </div>
    <div><el-image :src="fullscreenurl" alt="" fit="fill" @click="fullscreenclick" class="icon_style"></el-image>
      <div>全屏</div>
    </div>




  </div>
  <div class="group3">
    <div><el-image :src="selectedImage === 1 ? selectedWireframeUrl : wireframeurl" fit="fill" @click="wireframeclick"
        class="icon_style"></el-image>
      <div>线框</div>
    </div>
    <div> <el-image :src="selectedImage === 2 ? selectedReallyUrl : reallyurl" alt="" fit="fill" @click="reallyclick"
        class="icon_style"></el-image>
      <div>真实</div>
    </div>
    <div><el-image :src="selectedImage === 3 ? selectedColorUrl : colorurl" alt="" fit="fill" @click="colorclick"
        class="icon_style"></el-image>
      <div>着色</div>
    </div>
  </div>
  <el-progress v-if="view_loading" :percentage="percent" :stroke-width="15" striped-flow />
  <el-dialog v-model="bgcdialog" title="请选择类型" width="500" venter>
    <el-radio-group v-model="bgc">
      <el-radio value="color">背景颜色</el-radio> <el-color-picker v-model="bgccolor" show-alpha />
      <el-radio value="image">背景图片</el-radio><el-upload v-model:file-list="bgc_fileList"
        action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15" list-type="picture-card" limit="4"
        :on-preview="handlePictureCardPreview" :on-remove="handleRemove">
        <el-icon>
          <Plus />
        </el-icon>
      </el-upload>
    </el-radio-group>


    <template #footer>
      <div class="dialog-footer">
        <el-button type="primary" @click="bgcConfirm">确定</el-button>
        <el-button @click="bgcCancel">
          取消
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: 'BimViewer',
  props: {
    msg: String
  },
  components: {
    VueDragResize
  }
}
</script>
<script setup>
import VueDragResize from 'vue-drag-resize/src';
import * as THREE from 'three';
import "../css/bimviewer.css"
import { ref, onMounted, reactive, getCurrentInstance } from 'vue'
import Initialize from "../js/Initialize.js";
import Simulation from "../js/simulation.js";
import transformToReactiveStructure from "../js/transformToReactiveStr.js"
import NodeClick from "../js/NodeClick.js"
import CanvasOperation from "../js/CanvasOperation.js"
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/addons/loaders/DRACOLoader.js';
import ModelShow from '@/img/modelshow.svg';
import ModelHide from '@/img/modelhide.svg';
import { useRouter } from 'vue-router'
import eventBus from '../js/eventBus.js';
// import { getCookie } from '../store/cookieUtil.js';
// import handleError from '../config/HandleError.js'
// const projectinfo = JSON.parse(sessionStorage.getItem("projectinfo"))
// const user = JSON.parse(sessionStorage.getItem("user"))
const router = useRouter();
const { proxy } = getCurrentInstance()
const filename = ref();
const pouqieurl = ref(require('@/img/pouqie2.svg'));
const styleurl = ref(require('@/img/style2.svg'))
const measureurl = ref(require('@/img/measure2.svg'))
const rotateurl = ref(require('@/img/rotate2.svg'))
const bgcurl = ref(require('@/img/bgc2.svg'))
const fullsceneurl = ref(require('@/img/fullscene2.svg'))
const fullscreenurl = ref(require('@/img/fullscreen2.svg'))
const Closeurl = require('@/img/Close.svg')
const Navigatelogourl = require('@/img/navigatelogo.svg')
const gridHelperurl = ref(require('@/img/mesh2.svg'))
const wireframeurl = ref(require('@/img/wireframe2.svg'))
const reallyurl = ref(require('@/img/really2.svg'))
const colorurl = ref(require('@/img/color2.svg'))
const selectedWireframeUrl = ref(require('@/img/wireframe.svg'))
const selectedReallyUrl = ref(require('@/img/really.svg'))
const selectedColorUrl = ref(require('@/img/color.svg'))
const treeurl = ref(require('@/img/tree2.svg'))
const atrributeurl = ref(require('@/img/atrribute2.svg'))
const axisHelperurl = ref(require('@/img/axis.svg'))
const modelurl = ref(require('@/img/model.svg'))
const selectedImage = ref(2)
const defaultProps = {
  children: 'children',
  label: 'label',
  class: customClass
}
const bgcdialog = ref(false)
const bgc = ref('color')
const { scene, camera, renderer, controls, directionalLight } = Initialize();
let GLBdata = ref()
let flag = ref(0)
let isLeftVisible = ref(false);
let leaf_Parameters = ref([])
let model = null;
const activeNames = ref([])
const view_loading = ref(false)
const bgccolor = ref('#ffffff')
const bgc_fileList = ref();
const percent = ref(0)
const searchkeyinput = ref('')
const treeRef = ref(null)
let VueDragplus = reactive({
  initialWidth: window.innerWidth * 0.13,
  initialHeight: window.innerHeight - 70,
  initialX: window.innerWidth * 0.87,
  initialY: 70,
})
const file_version = ref()
let storeMeterialArray = [];//记录多材质
let selectThingArray = [];//记录上次选中的对象
let materialMap = new Map();
const Nodeclick = new NodeClick(scene);
const Canvasoperation = new CanvasOperation(camera, scene, renderer, controls);
const center = new THREE.Vector3();//原始模型的中心点
onMounted(async () => {
  //初始化
  const fileinfo = JSON.parse(sessionStorage.getItem('fileinfo'))
  console.log(fileinfo)
  // file_version.value = fileinfo.file_version
  // filename.value = fileinfo.file_name
  // const chunkSize = 1024 * 1024;
  // const totalChunks = Math.ceil(fileinfo.FileSize / chunkSize);
  // let base64List = []
  // let flag = true;
  // for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
  //   const file_data = new URLSearchParams();
  //   file_data.append('Token', getCookie('token'));
  //   file_data.append('ProjectId', projectinfo._id);
  //   file_data.append('Account', user.emailname);
  //   file_data.append('FileId', fileinfo._id);
  //   // file_data.append('Fileversion', item.FileVersion);
  //   file_data.append('StartPosition', chunkIndex * chunkSize);
  //   await new proxy.$request(proxy.$urls.m().DownloadFile, file_data).modepost().then(res => {
  //     if (res.status != 200) {
  //       new proxy.$tips(res.data.message, "warning").Mess_age()
  //       flag = false;
  //       return;
  //     }
  //     else {
  //       if (res.data.Error == 0 && res && res.data && res.data.FileContent && res.data.FileContent.$binary && res.data.FileContent.$binary.base64) {
  //         console.log(res)
  //         base64List.push(res.data.FileContent.$binary.base64);
  //       }
  //       else {
  //         const errorMessage = handleError(res);
  //         new proxy.$tips(errorMessage, "error").Mess_age();
  //         flag = false;
  //         return;
  //       }
  //     }
  //   })

  // }
  // if (flag == false) return;
  // const combinedBase64 = base64List.join('');
  // const byteCharacters = atob(combinedBase64);
  // const byteNumbers = new Array(byteCharacters.length);
  // for (let i = 0; i < byteCharacters.length; i++) {
  //   byteNumbers[i] = byteCharacters.charCodeAt(i);
  // }
  // const byteArray = new Uint8Array(byteNumbers);
  // const blob = new Blob([byteArray], { type: 'model/gltf-binary' });
  // const fileURL = URL.createObjectURL(blob);
  // console.log(fileURL)
  //Vue 组件在 setup() 函数中，还未完成 DOM 的挂载，导致可能无法获取 DOM 节点。
  document.getElementById('my-three').appendChild(renderer.domElement);//将画布插入到my-three中，renderer.domElement是渲染的结果

  const loader = new GLTFLoader();
  const dracoLoader = new DRACOLoader();
  dracoLoader.setDecoderPath('././draco/');
  loader.setDRACOLoader(dracoLoader)
  // loader.load('././model/1.0.glb', function (gltf){
  loader.load("https://bim.bfine-tech.com"+convertWindowsPathToUnixPath(fileinfo.FilePath), function (gltf) {
    model = gltf.scene;
    // 计算模型的包围盒
    const box = new THREE.Box3().setFromObject(model);
    // 计算包围盒的中心
    box.getCenter(center);
    // 将模型的中心移动到原点
    model.position.sub(center);
    model.updateMatrixWorld(true);//更新物体及其后代的全局变换
    // 设置相机的位置
    camera.position.set(center.x, center.y * (0.3), center.z); // 根据包围盒中心的位置和距离设置相机
    Nodeclick.shadowMap(model);
    directionalLight.position.set(50, 100, 50);
    scene.add(model);
    console.log(gltf);
    const data = gltf.parser.json;
    GLBdata.value = transformToReactiveStructure(data.nodes);
    // view_loading.value=false
    // GLBdata = transformToReactiveStructure(nodes);
    console.log(GLBdata);
  },undefined, function (xhr) {
    console.log(xhr)
    // percent.value = Math.floor(xhr.loaded / xhr.total)*100;
    // console.log('加载进度' + percent.value);
    // view_loading.value=true

  });


})
function convertWindowsPathToUnixPath(windowsPath) {
    // 替换反斜杠为正斜杠，并处理驱动器字母
    let unixPath = windowsPath.replace(/\\/g, '/');
    // 去掉驱动器 "C:" 并将其转换为小写
    unixPath = unixPath.replace(/^[a-z]:/i, '');
    // 去掉多余的 '/uploads' 前缀
    if (unixPath.startsWith('/')) {
        unixPath = unixPath.slice(1);
    }
    return '/' + unixPath; // 以正斜杠开头
}
const handleButtonClick = (data) => {

  Nodeclick.handleButtonClick(data);
}
let isClick = false; // 是否触发了点击事件标志
//鼠标点击选中模型 
renderer.domElement.addEventListener('click', (event) => {
  if (!isClick) return;
  // .offsetY、.offsetX以canvas画布左上角为坐标原点,单位px
  const px = event.offsetX;
  const py = event.offsetY;
  //屏幕坐标px、py转WebGL标准设备坐标x、y
  //width、height表示canvas画布宽高度
  const x = (px / window.innerWidth) * 2 - 1;
  const y = -(py / window.innerHeight) * 2 + 1;
  //创建一个射线投射器`Raycaster`
  const raycaster = new THREE.Raycaster();
  // 通过摄像机和鼠标位置更新射线
  raycaster.setFromCamera(new THREE.Vector2(x, y), camera);
  const groups = scene.children.filter(child => child instanceof THREE.Group);
  // 获取所有 Group 的子对象，也就是递归查找所有可能的 Mesh 对象
  let objectsToIntersect = [];
  groups.forEach(group => {
    group.traverse(child => {
      if (child instanceof THREE.Mesh) {
        objectsToIntersect.push(child);  // 只添加 Mesh 对象
      }
    });
  });
  const intersects = raycaster.intersectObjects(objectsToIntersect);
  if (intersects.length > 0) {
    // flag.value = 1;
    // atrributeurl.value = require('@/img/atrribute.svg')
    // atrribute = 1;
    let i = 0;
    let j = 0;
    let flag = false;
    if (box_save) {
      for (i = 0; i < intersects.length; i++) {
        for (const value of box_save.clipplaneMap.values()) {
          console.log(box_save.clipplaneMap.values())
          const distanceToPlane = value.distanceToPoint(intersects[i].point);
          if (distanceToPlane <= 0) {
            break;
          }
          else {
            j++;
          }
        }
        if (j == box_save.clipplaneMap.size) {
          //找到符合的mesh
          flag = true;
          break;
        }
      }
    }
    else {
      flag = true;
    }
    if (!flag) return;
    const selectedObject = intersects[i].object;
    console.log(selectedObject);
    materialMap.forEach((value) => {
      const { selectThingArray: savedSelectThings, storeMeterialArray: savedMaterials } = value;
      savedSelectThings.forEach((thing, index) => {
        thing.material = savedMaterials[index];
      });
    });
    materialMap.clear();
    //保存原始材质参数
    ({ leafNodes: selectThingArray, materials: storeMeterialArray } = Nodeclick.traverseAndCollectLeafNodesAndMaterials(selectedObject));
    //设置构件材质
    const newMaterial = storeMeterialArray;
    const newMash = selectThingArray;
    newMaterial.forEach((m0, index) => {
      const m = m0.clone();
      // m.color.set(0x87cefa);
      m.color.set(0xff0000)
      m.transparent = true;
      m.opacity = 0.6;
      newMash[index].material = m;
    });
    materialMap.set('test', {
      selectThingArray: [...selectThingArray],   // 存储副本而非引用
      storeMeterialArray: [...storeMeterialArray] // 存储副本而非引用
    });
    //更新属性的窗口
    console.log(materialMap)
    leaf_Parameters.value = Nodeclick.findParameters(intersects[i].object)
    activeNames.value = leaf_Parameters.value.map(group => group.GroupName);
  }
  else {//点击空白区域取消选中,清除数据
    materialMap.forEach((value) => {
      const { selectThingArray: savedSelectThings, storeMeterialArray: savedMaterials } = value;
      savedSelectThings.forEach((thing, index) => {
        thing.material = savedMaterials[index];
      });
    });
    materialMap.clear();
    // flag.value = 0;
    // atrributeurl.value = require('@/img/atrribute2.svg')
    // atrribute = 0;
    leaf_Parameters.value = null;
  }


})
renderer.domElement.addEventListener('wheel', Canvasoperation.MouseWheel, { passive: false })
const handleNodeClick = (n) => {
  if (n.isLeaf) {
    leaf_Parameters.value = n.modelInfo;
    flag.value = 1;
    atrributeurl.value = require('@/img/atrribute.svg')
    atrribute = 1;
    // Name.value = n.modelInfo.name;
    activeNames.value = leaf_Parameters.value.map(group => group.GroupName);
  }
  // 恢复原始材质参数
  materialMap.forEach((value) => {
    const { selectThingArray: savedSelectThings, storeMeterialArray: savedMaterials } = value;
    savedSelectThings.forEach((thing, index) => {
      thing.material = savedMaterials[index];
    });
  });
  materialMap.clear();
  const names = Nodeclick.traverseAndCollectNames(n);
  names.forEach(name => {
    const selectedObject = scene.getObjectByName(name);
    ({ leafNodes: selectThingArray, materials: storeMeterialArray } = Nodeclick.traverseAndCollectLeafNodesAndMaterials(selectedObject));
    //设置构件材质
    const newMaterial = storeMeterialArray;
    const newMash = selectThingArray;
    newMaterial.forEach((m0, index) => {
      const m = m0.clone();//克隆材质的好处：确保对材质的修改不会影响到其他对象。保持相应对象的材料之间的独立性，可以让你在不承担破坏性更改风险的情况下，进行视觉效果上的修改。
      // 尽量避免对共享材质进行直接修改，除非你确定所有使用该材质的对象都需要进行相同的修改。
      m.color.set(0xff0000)
      m.transparent = true;
      m.opacity = 0.6;
      newMash[index].material = m;
    });
    materialMap.set(name, {
      selectThingArray: [...selectThingArray],   // 存储副本而非引用
      storeMeterialArray: [...storeMeterialArray] // 存储副本而非引用
    });

  });

}
const filterTree = () => {
  treeRef.value.filter(searchkeyinput.value);
}
const filterNode = (value, data) => {
  if (!value) return true;
  return data.label.indexOf(value) !== -1;
}
const CloseList = () => {

  treeurl.value = require('@/img/tree2.svg')
  isLeftVisible.value = false
  tree = 0;

}
const customClass = (node, data) => {
  if (node.level == 1) { // 判断当前节点是否为根节点
    return 'background'; // 返回根节点的特定样式类
  }
  return '';
};
const CloseListFamilyName = () => {
  atrributeurl.value = require('@/img/tree2.svg')
  flag.value = 0
  atrribute = 0;
}

let rotate = 0;
const rotateclick = () => {
  if (rotate == 0) {
    const newcenter = new THREE.Vector3();
    const points = []
    //未选中构件，默认旋转中心是模型中心
    if (materialMap.size === 0) {
      const intersects = Canvasoperation.Centermouseraycaster();
      console.log(intersects, newcenter)
      if (intersects.length > 0) {
        newcenter.copy(intersects[0].point)
        mouseraycaster = false;

      }
      // newcenter.copy(new THREE.Vector3(0, 0, 0))
    }
    //选中构件，默认旋转中心是构件中心
    else {
      materialMap.forEach((value) => {
        const { selectThingArray: savedSelectThings } = value;
        savedSelectThings.forEach((mesh) => {
          points.push(Nodeclick.getBoundingSphereCenterInWorld(mesh))

        });
      });
      newcenter.copy(Nodeclick.computeCentroid(points))
    }
    controls.target.copy(newcenter)
    controls.autoRotate = true;
    controls.autoRotateSpeed = 1.5;
    // renderer.domElement.removeEventListener('wheel', Canvasoperation.MouseWheel)
    rotateurl.value = require('@/img/rotate1.svg')
    rotate = 1;
  }
  else {
    controls.autoRotate = false;
    // renderer.domElement.addEventListener('wheel', Canvasoperation.MouseWheel, { passive: false })
    rotateurl.value = require('@/img/rotate2.svg')
    rotate = 0;

  }
}
//剖切
let pouqie = 0
let box_save = null;
//创建包围盒
let simulation = null;
const pouqieclick = () => {
  if (pouqie == 0) {
    eventBus.on('clickStatusRefresh', clickStatusRefresh);
    styleurl.value = require('@/img/style1.svg')
    pouqie = 1;
    simulation = new Simulation(model, scene, camera, renderer, controls, box_save);
    console.log(simulation)
  }
  else {
    styleurl.value = require('@/img/style2.svg');
    box_save = simulation.dispose();
    simulation = null;
    pouqie = 0;
  }

}
const clickStatusRefresh = () => {
  isClick = false;
}
//全屏
var full = 0;
const fullscreenclick = () => {
  switch (full) {
    case 0:
      full = 1;
      document.body.requestFullscreen();
      fullscreenurl.value = require('@/img/fullscreen1.svg');
      break;
    case 1:
      full = 0;
      document.exitFullscreen();
      fullscreenurl.value = require('@/img/fullscreen2.svg');
      break;
    default:
      new proxy.$tips("发生错误", "error").Mess_age()
  }

}
let mesh = 0;
let gridHelper = null;
const gridHelperclick = () => {
  if (mesh == 0) {
    gridHelperurl.value = require('@/img/mesh.svg')
    // 添加网格地面
    gridHelper = new THREE.GridHelper(100, 100)
    scene.add(gridHelper)
    mesh = 1;
  }
  else {
    gridHelperurl.value = require('@/img/mesh2.svg')
    scene.remove(gridHelper);
    mesh = 0;
  }
}

const measureclick = () => {
  measureurl.value = require('@/img/measure1.svg')
}
const returnmodel = () => {
  renderer.clippingPlanes = [];
  styleurl.value = require('@/img/style2.svg');
  if (simulation) {
    simulation.dispose();
    simulation = null;
  }
  box_save = null;
  pouqie = 0;
}
let textureMeshArray = [];
let textureMeterialArray = [];
let temp_model = null;
//线框
const wireframeclick = () => {
  if (selectedImage.value == 1) {
    return;
  }
  if (selectedImage.value == 3) {
    textureMeshArray.forEach((mesh, index) => {
      mesh.material = textureMeterialArray[index];
    });
  }
  selectedImage.value = 1;
  scene.remove(model);
  // Nodeclick.traverseAndCollectMaterials(model, true)
  temp_model = model.clone();
  Nodeclick.traverseallMesh(temp_model)
  scene.add(temp_model);
  console.log(scene)
}
//真实
const reallyclick = () => {
  if (selectedImage.value == 2) {
    return;
  }
  if (selectedImage.value == 1) {
    // Nodeclick.traverseAndCollectMaterials(model, false)
    scene.remove(temp_model);
    scene.add(model)
    console.log(scene)
  }
  else if (selectedImage.value == 3) {
    textureMeshArray.forEach((mesh, index) => {
      mesh.material = textureMeterialArray[index];
    });
  }
  selectedImage.value = 2;
}
//着色
const colorclick = () => {
  if (selectedImage.value == 3) {
    return;
  }
  if (selectedImage.value == 1) {
    scene.remove(temp_model);
    scene.add(model)
  }
  selectedImage.value = 3;
  ({ TextureNodes: textureMeshArray, materials: textureMeterialArray } = Nodeclick.traverseMaterialsCollectTexture(model));
  console.log(model)
}
//构建树
let tree = 0;
const treeclick = () => {
  if (tree == 0) {
    treeurl.value = require('@/img/tree.svg')
    isLeftVisible.value = true
    tree = 1;
  }
  else {
    treeurl.value = require('@/img/tree2.svg')
    isLeftVisible.value = false
    tree = 0;
  }

}
let atrribute = 0;
//属性
const atrributeclick = () => {
  if (atrribute == 0) {
    atrributeurl.value = require('@/img/atrribute.svg')
    flag.value = 1
    atrribute = 1;
  }
  else {
    atrributeurl.value = require('@/img/atrribute2.svg')
    flag.value = 0
    atrribute = 0;
  }
}
//背景
const bgcclick = () => {
  bgcdialog.value = true;
}
const bgcConfirm = () => {
  if (bgc.value == 'color') {
    scene.background = new THREE.Color(bgccolor.value)

  }
  else {
    var textureloader = new THREE.TextureLoader();
    bgc_fileList.value.forEach(element => {
      textureloader.load(element)
      console.log(element)
    });
    scene.background = textureloader;

  }
  bgcdialog.value = false;

}
const bgcCancel = () => {
  bgcdialog.value = false;
}
let axis = 0;
// 添加坐标轴辅助线
let axesHelper = null;
const axisHelperclick = () => {
  if (axis == 0) {
    axisHelperurl.value = require('@/img/axis2.svg')
    axesHelper = new THREE.AxesHelper(100);
    scene.add(axesHelper)
    axis = 1;
  }
  else {
    axisHelperurl.value = require('@/img/axis.svg')
    scene.remove(axesHelper);
    axis = 0;
  }
}
//返回到上一页
const CloseBimtitle = () => {
  router.back();//浏览器历史回退
}
//主页
let home = 0;
const styleclick = () => {
  if (home == 0) {
    pouqieurl.value = require('@/img/pouqie1.svg')
    camera.position.set(center.x, center.y * (0.3), center.z);
    controls.target.set(0, 0, 0);
    camera.updateProjectionMatrix();
    home = 1;
  }
  else {
    pouqieurl.value = require('@/img/pouqie2.svg');
    home = 0;
  }

}
let isDragging = false;  // 标记鼠标是否处于拖拽状态
//监听鼠标按下事件
renderer.domElement.addEventListener('mousedown', function (event) {
  if (event.button === 0) { // 左键
    isDragging = true;
    isClick = true;
    // previousMousePosition.set(event.clientX, event.clientY);
  }
});
// 鼠标移动事件，旋转模型
let mouseraycaster = true;
const newcenter = new THREE.Vector3();
let points = []
renderer.domElement.addEventListener('mousemove', function (event) {
  if (!isDragging) return;
  //未选中构件，默认旋转中心是模型中心
  if (materialMap.size === 0) {
    if (mouseraycaster) {
      const intersects = Canvasoperation.Centermouseraycaster();
      console.log(intersects, newcenter)
      if (intersects.length > 0) {
        newcenter.copy(intersects[0].point)
        mouseraycaster = false;
      }
    }

    // newcenter.copy(camera.position)
  }
  //选中构件，默认旋转中心是构件中心
  else {
    if (mouseraycaster) {
      points = [];
      materialMap.forEach((value) => {
        const { selectThingArray: savedSelectThings } = value;
        savedSelectThings.forEach((mesh) => {
          points.push(Nodeclick.getBoundingSphereCenterInWorld(mesh))

        });
      });
      newcenter.copy(Nodeclick.computeCentroid(points))
      mouseraycaster = false;

    }

  }
  controls.target.copy(newcenter);  // 更新旋转焦点为模型中心
  controls.update();
  // console.log(camera.position.clone(), controls.target);
  isClick = false;
  event.preventDefault();
});
// 鼠标松开事件，结束旋转
renderer.domElement.addEventListener('mouseup', function (event) {
  if (event.button === 0) {
    isDragging = false;
    mouseraycaster = true;
  }
});
// 如果滚出画布则停止旋转
renderer.domElement.addEventListener('mouseleave', function () {
  isDragging = false;
});



</script>
