<template>
    <div class="notice_out">
        <div class="notice_aa">
            <div class="notice_a">
                消息中心
            </div>
        </div>
        <div class="notice_bb">
            <el-tabs v-model="notice_activeName" type="card" class="notice_demo-tabs" @tab-change="handleClick">
                <el-tab-pane label="全部" name="first">
                    <noticeitem></noticeitem>
                </el-tab-pane>
                <el-tab-pane label="未读" name="second"><noticeitem ></noticeitem></el-tab-pane>
            </el-tabs>
        </div>

    </div>

</template>
<script>
import "../css/notice.css"
import noticeitem from "./noticeitem.vue"
export default {
    name: 'NoticeA',
    props: {
        msg: String
    },
    components: {
        noticeitem,
    }

}
</script>
<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
const notice_activeName = ref('first');
const store = useStore()
const handleClick=(e)=>{
    store.dispatch('noticeitemStatusfun', e);
};

</script>