const fileConvert = class {
    convertToTree(data) {
        const map = new Map();
        // 初始化 map，将每个项目放入 map 中
        data.forEach(item => {
            map.set(item._id, {
                label: item.Name,
                value: item._id,
                parentID: item.ParentID,
                children: []
            });
        });

        const tree = [];
        // 构建树形结构
        map.forEach((value, key) => {
            if (value.parentID == 0) {
                tree.push(value);
            }
            if (data.some(item => item.ParentID === key)) {
                // 找到其子节点，并将其添加到 children
                const parent = map.get(key);
                const children = data.filter(item => item.ParentID === key);
                children.forEach(child => {
                    parent.children.push(map.get(child._id));
                });
            }
        });

        return tree;
    }
    buildTree(data) {
        const map = {};
        const roots = [];
        data.forEach(item => {
            map[item._id] = { ...item, children: [] };
        });

        // 决定哪项是根节点（FileParentId 为 0）
        data.forEach(item => {
            if (item.FileParentId === 0) {
                roots.push(map[item._id]); // 添加到根节点数组
            } else {
                // 如果不是根节点，找到其父节点
                const parent = map[item.FileParentId];
                if (parent) {
                    parent.children.push(map[item._id]); // 将子节点添加到其父节点
                }
            }
        });
        // 交换父节点与最大 FileVersion 的子节点位置
        let maxVersionChildren = [];
        for (let i = 0; i < roots.length; i++) {
            const maxVersionChild = swapMaxVersionChild(roots[i], i);
            maxVersionChildren.push(maxVersionChild);
        }
        return maxVersionChildren;
        function swapMaxVersionChild(node, i) {
            if (node.children.length === 0) {
                node.isPromoted = true;
                node.seq = i;
                return node; // 如果没有子节点，直接返回
            }

            // 查找最大 FileVersion 的子节点
            let maxVersionChild = node.children[0]; // 假设第一个是最大的
            for (let child of node.children) {
                if (parseFloat(child.FileVersion) > parseFloat(maxVersionChild.FileVersion)) {
                    maxVersionChild = child; // 更新最大版本节点
                }
            }
            // 从父节点中移除最大版本子节点
            const indexOfMax = node.children.indexOf(maxVersionChild);
            node.children.splice(indexOfMax, 1); // 移除最大版本子节点

            // 将原父节点添加为最大版本子节点的子节点
            maxVersionChild.children.push(node);
            node.children.forEach(child => {
                maxVersionChild.children.push(child);
            });
            node.children = [];
            node.isPromoted = false;
            maxVersionChild.seq = i;
            return maxVersionChild;

        }
    }
    initializeFileData(files, progress) {
        if (progress) {
            return files.map(file => {
                // 查找与当前 file._id 匹配的 progress 项
                const matchingProgress = progress.find(p => p._id === file._id);

                // 如果找到匹配的 progress，则使用它的 progress 值，否则设置为 0
                const progressValue = matchingProgress ? matchingProgress.progress : 0;

                return {
                    ...file,
                    progress: progressValue,   // 赋值为对应的 progress
                    status_progress: 'active', // 保持原来的状态
                    seq: 0                     // seq 设置为 0
                };
            });
        }
        else {
            return files.map(file => ({
                ...file,
                progress: 0,
                status_progress: 'active',
                seq: 0
            }));
        }

    }


}
export default fileConvert