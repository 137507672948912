<template>
  <section>
    <div class="head_title">
      <img src="../img/navigatelogo.svg" alt="" class="logo">
      <router-link to="/projectList" class="project">项目中心</router-link>
      <router-link to="/datacenter" class="static">数据中心</router-link>
      <!-- <div class="project"><router-link to="/projectitem">项目中心</router-link></div>
      <div class="project"><router-link to="/projectitem">数据中心</router-link></div> -->
      <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
        <!-- <el-tab-pane label="User" name="first">项目中心</el-tab-pane>
    <el-tab-pane label="Config" name="second">数据中心</el-tab-pane> -->
      </el-tabs>
      <img src="../img/notice1.svg" @click="noticeshow" class="noticesvg">
      <el-avatar :src="circleUrl" class="head_avatar" />
      <el-dropdown>
        <span class="nickname">
          {{ nickname }}
          <el-icon class="el-icon--right">
            <arrow-down />
          </el-icon>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item>个人中心</el-dropdown-item>
            <el-dropdown-item @click="logout">退出登录</el-dropdown-item>

          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <!-- <div class="nickname">struggle66</div> -->
    </div>
    
    <router-view></router-view>
  </section>
</template>

<script>
import '../css/head.css'
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { eraseCookie } from '../store/cookieUtil.js';
export default {
  name: 'HeaderComp',
  props: {
    msg: String
  }
}
</script>
<script setup>
const router = useRouter()
const circleUrl = ref('https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png')
const nickname = ref('请登录')
const noticeshow = () => {
  console.log("njfdnv");
  router.push({
    name: "notice"
  })
}
// const returnproject = () => {
//   router.push({
//     name: "projectList"
//   })
// }
onMounted(() => {
  nickname.value = JSON.parse(sessionStorage.getItem("user")).emailname
  console.log(nickname)
})
const logout = () => {
  localStorage.clear();
  sessionStorage.clear();
  eraseCookie('token');
  if ('caches' in window) {
    caches.keys().then(function (cacheNames) {
      cacheNames.forEach(function (cacheName) {
        caches.delete(cacheName);
      });
    });
  }
  router.push({
    name: "login"
  })
}
</script>